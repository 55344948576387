import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TableOrders from './TableOrder';
import AddOrder from './AddOrder';
import Chart from './Chart';
import { Toaster } from 'sonner';
import { useManualTradingContext, ManualTradingProvider } from '../../context/ManualTradingContext';
import { MdAccountBalanceWallet } from "react-icons/md";
import TablePositions from './TablePositions';
import { formatCurrency } from '../../utils/formatCurrency';

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'Manual trading', link: '/manual-trading/:keyId' }
];

const ManualTrading: React.FC = () => {
  const { keyId } = useParams<{ keyId: string }>();
  const { symbol } = useParams<{ symbol: string }>();

  return (
    <ManualTradingProvider keyId={keyId} sym={symbol}>
      <ManualTradingContent />
    </ManualTradingProvider>
  );
};

const ManualTradingContent: React.FC = () => {
  const { accountInfo } = useManualTradingContext();

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <Toaster position="bottom-center" richColors />

      <div className='flex gap-2 items-center mb-4'>
        <MdAccountBalanceWallet className='text-xl' />
        {/* <p>Avaliable: {accountInfo?.balance.balance ? formatCurrency(accountInfo?.balance.balance) : formatCurrency(0)}</p> */}
        <p>Avaliable: {accountInfo?.market.toUpperCase() == "FUTURES" ? formatCurrency(accountInfo.accountFuture?.balance) :
          (
            (accountInfo?.accountSpot || []).map((item, index) => (
              <>
                {item.asset}: {item.free}/{item.locked}
              </>

            ))
          )
        }</p>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
        <div className='h-[450px] md:h-full md:col-span-2'>
          <Chart />
        </div>
        <div className='md:col-span-1'>
          <AddOrder />
        </div>
      </div>

      {accountInfo?.market.toUpperCase() != "SPOT" && (
        <>
          <h2 className="text-title-md2 font-semibold text-black dark:text-white mt-4 mb-2">Positions {`(${accountInfo?.positions && accountInfo?.positions.length ? accountInfo?.positions.length : 0})`}</h2>

          <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5 mt-4">
            <div className="col-span-12">
              <TablePositions positions={accountInfo?.positions} key={accountInfo?.positions ? accountInfo.positions.length : 'initial'} />
            </div>
          </div>
        </>
      )
      }

      <h2 className="text-title-md2 font-semibold text-black dark:text-white mt-4 mb-2">Orders {`(${accountInfo?.openOrder && accountInfo?.openOrder.length ? accountInfo?.openOrder.length : 0})`}</h2>

      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5 mt-4">
        <div className="col-span-12">
          <TableOrders orders={accountInfo?.openOrder} key={accountInfo?.openOrder ? accountInfo.openOrder.length : 'initial'} />
        </div>
      </div>
    </>
  );
};

export default ManualTrading;