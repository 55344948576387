import React from 'react';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import TableBotsAll from './TableBotsAll';
import Loader from '../../../common/Loader';
import BtnPrimary from '../../../components/Buttons/BtnPrimary';
import { HiOutlineRefresh } from "react-icons/hi";
import { useBotsContext } from '../../../context/BotsAllContext';

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'Bots all', link: '/bots-all' }
];

const BotsAll: React.FC = () => {
  const { botsAll, isLoading, error, getBotsAll, totalCount, handlePageChange, currentPage } = useBotsContext();

  if (isLoading) {
    return <Loader />;
  }

  const handleRefresh = () => {
    getBotsAll(currentPage); 
  };


  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <BtnPrimary onClick={handleRefresh} className='mb-4'><HiOutlineRefresh /> Refresh</BtnPrimary>
      
      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5 mt-4">
        <div className="col-span-12">
          <TableBotsAll 
            bots={botsAll} 
            updateBots={getBotsAll}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            currentPage={currentPage} />
        </div>
      </div>
    </>
  );
};

export default BotsAll;