import React, { createContext, useContext, useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import api from '../utils/api';
import { KEY, GetApiKeysAll, OrderHistory, GetOrderHistoryAll } from '../types/key';
import { ApiResponse } from '../types/api';
import { toast } from 'sonner';

interface ApiKeysContextProps {
  keys: KEY[];
  keysAll: KEY[];
  keysPerPage: number;
  totalCount: number;
  currentPage: number;
  isModalDeleteOpen: boolean;
  isModalEditOpen: boolean;
  isModalHistoryOrders: boolean;
  selectedKey: KEY | null;
  orderHistory: OrderHistory[];
  orderHistoryPage: number;
  orderHistoryTotalCount: number;
  isModalTransfer: boolean;
  setKeys: Dispatch<SetStateAction<KEY[]>>; // Обновленный тип
  fetchKeys: (page: number, keysPerPage: number) => void;
  handlePageChange: (selectedItem: { selected: number }) => void;
  toggleModalDelete: () => void;
  toggleModalEdit: () => void;
  toggleModalHistoryOrders: () => void;
  handleDeleteKey: (key: KEY) => void;
  deleteKey: (id: string) => Promise<boolean>;
  handleDeleteConfirm: (result: boolean) => void;
  handleSaveUpdate: (result: boolean) => void;
  handleEditKey: (key: KEY) => void;
  fetchOrderHistory: (subAccountId: string, page: number) => void;
  handleHistoryOrdersKey: (key: KEY) => void;
  handleTransferOpen: (key: KEY) => void;
  handleTransfer: (result: boolean) => void;
  toggleModalTransfer: () => void;
  handleOrderHistoryPageChange: (selectedItem: { selected: number }) => void;
}

const ApiKeysContext = createContext<ApiKeysContextProps | undefined>(undefined);

export const useApiKeysContext = () => {
  const context = useContext(ApiKeysContext);
  if (!context) {
    throw new Error('useApiKeysContext must be used within an ApiKeysProvider');
  }
  return context;
};

export const ApiKeysProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [keys, setKeys] = useState<KEY[]>([]);
  const [keysAll, setKeysAll] = useState<KEY[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalHistoryOrders, setIsModalHistoryOrders] = useState(false);
  const [selectedKey, setSelectedKey] = useState<KEY | null>(null);
  const [orderHistory, setOrderHistory] = useState<OrderHistory[]>([]);
  const [orderHistoryPage, setOrderHistoryPage] = useState(0);
  const [orderHistoryTotalCount, setOrderHistoryTotalCount] = useState(0);
  const [isModalTransfer, setIsModalTransfer] = useState(false);

  const toggleModalTransfer = () => setIsModalTransfer(!isModalTransfer);

  const orderHistoryPerPage = 10;
  const keysPerPage = 20;
  const keysPerPageDropdown = 100;

  useEffect(() => {
    fetchKeys(currentPage, keysPerPage);
  }, [currentPage]);

  useEffect(() => {
    fetchKeysALL(currentPage)
  }, []);

  const fetchKeys = useCallback(async (page: number, keysPerPage: number) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await api.post<ApiResponse<GetApiKeysAll>>(`/api/api_keys/search`, {
        "pagination": {
          "page": page + 1,
          "limit": 20
        }
        }
      );
      if (response.data && response.data.result) {
        setKeys(response.data.result.items);
        setTotalCount(response.data.result.totalCount);
      } else {
        setKeys([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error('Error fetching keys:', error);
    }
  }, [api, keysPerPage, setKeys, setTotalCount]);

  const fetchKeysALL = useCallback(async (page: number) => {
    try {
      const response = await api.post<ApiResponse<GetApiKeysAll>>(`/api/api_keys/search`, {
        "pagination": {
          "page": page + 1,
          "limit": 100
        }
        }
      );
      
      if (response.data && response.data.result) {
        setKeysAll(response.data.result.items);
      } else {
        setKeys([]);
      }
    } catch (error) {
      console.error('Error fetching keys:', error);
    }
  }, [api, keysPerPage, setKeys, setTotalCount]);

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    fetchKeys(selectedItem.selected, keysPerPage);
  };

  const toggleModalDelete = () => setIsModalDeleteOpen(!isModalDeleteOpen);
  const toggleModalEdit = () => setIsModalEditOpen(!isModalEditOpen);
  const toggleModalHistoryOrders = () => setIsModalHistoryOrders(!isModalHistoryOrders);

  const handleDeleteKey = (key: KEY) => {
    setSelectedKey(key);
    toggleModalDelete();
  };

  const deleteKey = async (id: string) => {
    try {
      const response = await api.delete(`/api/api_keys/${id}`);
      if (response.data.code === 200 && response.data.error === null) {
        console.log("Key deleted successfully");
        return true;
      } else {
        toast.error('Failed to delete key');
        return false;
      }
    } catch (error) {
      toast.error('An error occurred while deleting user');
      return false;
    }
  };

  const handleDeleteConfirm = async (result: boolean) => {
    if (result && selectedKey?.id) {
      try {
        const success = await deleteKey(selectedKey.id);
        if (success) {
          toast.success('Key deleted successfully');
          await fetchKeys(currentPage, keysPerPage);
        } else {
          toast.error('Failed to delete key');
        }
      } catch (error) {
        toast.error('Failed to delete key');
      }
    }
    toggleModalDelete();
  };

  const handleSaveUpdate = (result: boolean) => {
    if (result) {
      // fetchKeys(currentPage);
      toast.success('Key edited successfully');
    } else {
      toast.error('Failed to edit key');
    }
  };

  const handleEditKey = (key: KEY) => {
    setSelectedKey(key);
    toggleModalEdit();
  };

  const fetchOrderHistory = useCallback(async (subAccountId: string, page: number) => {
    try {
      const response = await api.get<ApiResponse<GetOrderHistoryAll>>(`/api/api_keys/orders/${subAccountId}?page=${page + 1}&pageSize=${orderHistoryPerPage}`);
      if (response.data && response.data.result) {
        setOrderHistory(response.data.result.items);
        setOrderHistoryTotalCount(response.data.result.totalCount);
      } else {
        setOrderHistory([]);
        setOrderHistoryTotalCount(0);
      }
    } catch (error) {
      console.error('Error fetching order history:', error);
    }
  }, [api, orderHistoryPerPage, setOrderHistory, setOrderHistoryTotalCount]);

  const handleHistoryOrdersKey = (key: KEY) => {
    setSelectedKey(key);
    toggleModalHistoryOrders();
    if (key.subAccountId) {
      fetchOrderHistory(key.subAccountId, orderHistoryPage);
    }
  };

  const handleTransferOpen = (key: KEY) => {
    setSelectedKey(key);
    toggleModalTransfer();
  };

  const handleOrderHistoryPageChange = (selectedItem: { selected: number }) => {
    setOrderHistoryPage(selectedItem.selected);
    if (selectedKey?.subAccountId) {
      fetchOrderHistory(selectedKey.subAccountId, selectedItem.selected);
    }
  };

  const handleTransfer = async (result: boolean) => {
    if (result && selectedKey) {
      await fetchKeys(currentPage, keysPerPage);
    }
    if (!result) return;
    toggleModalTransfer();
  };

  const contextValue = {
    keys,
    totalCount,
    setKeys,
    currentPage,
    isModalDeleteOpen,
    isModalEditOpen,
    isModalHistoryOrders,
    selectedKey,
    keysPerPage,
    orderHistory,
    orderHistoryPage,
    orderHistoryTotalCount,
    isModalTransfer,
    fetchKeys,
    handlePageChange,
    toggleModalDelete,
    toggleModalEdit,
    keysAll,
    toggleModalHistoryOrders,
    handleDeleteKey,
    deleteKey,
    handleDeleteConfirm,
    handleSaveUpdate,
    handleEditKey,
    fetchOrderHistory,
    handleHistoryOrdersKey,
    handleTransferOpen,
    handleTransfer,
    toggleModalTransfer,
    handleOrderHistoryPageChange,
  };

  return (
    <ApiKeysContext.Provider value={contextValue}>
      {children}
    </ApiKeysContext.Provider>
  );
};