import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { Toaster, toast } from 'sonner';
import { BsRobot, BsTrash, BsPencil, BsPlusCircleFill, BsXCircleFill } from "react-icons/bs";
import InputField from '../../../components/Forms/Inputs/InputField';
import { Bot, TASK } from '../../../types/task';
import BtnPrimary from '../../../components/Buttons/BtnPrimary';
import { ApiResponse } from '../../../types/api';

interface CommentForBotProps {
  toggleModal: () => void,
  onSave?: (result: boolean) => void,
  uuidBot: string,
  commentDefault?: string,
  updateBots?: (page: number) => void,
  currentPage: number
}

const CommentForBot: React.FC<CommentForBotProps> = ({ toggleModal, onSave, uuidBot, commentDefault, updateBots, currentPage }) => {
  const [comment, setComment] = useState(commentDefault || '');
  const [isEditing, setIsEditing] = useState(!commentDefault);
  const [isCommentChanged, setIsCommentChanged] = useState(false);

  useEffect(() => {
    setComment(commentDefault || '');
    setIsEditing(!commentDefault);
    setIsCommentChanged(false); 
  }, [commentDefault]);

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
    setIsCommentChanged(e.target.value !== commentDefault); 
  };

  const handleSaveComment = async () => {
    if (!isCommentChanged) return; 

    try {
      const updateData = {
        comment: comment
      };

      await api.put<ApiResponse<string>>(`/bot-api/bots/${uuidBot}`, updateData);

      toast.success('Comment updated successfully');
      onSave?.(true);
      updateBots?.(currentPage);
      toggleModal();
    } catch (error) {
      toast.error('Failed to update comment');
      onSave?.(false);
    }
  };

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <form onSubmit={(e) => { e.preventDefault(); handleSaveComment(); }}>
        <div className="flex-grow mb-2">
          <div>
            <label className="mb-3 block text-black dark:text-white">
              Comment
            </label>
            <div className="relative">
              <textarea 
                rows={6}
                placeholder="Enter comment..." 
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                value={comment}
                onChange={handleCommentChange}
              />
            </div>
          </div>
        </div>

        <div className='flex items-center justify-between gap-4 mt-auto'>
          <BtnPrimary className='w-full' type='submit' disabled={!isCommentChanged}>
            <BsPlusCircleFill /> Save comment
          </BtnPrimary>
          <BtnPrimary
            className='w-full'
            style="outline"
            onClick={() => {
              toggleModal();
            }}
          >
            <BsXCircleFill /> Close
          </BtnPrimary>
        </div>
      </form>
    </>
  );
};

export default CommentForBot;