import React, { useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TableDealsStatistics from './TableDealsStatistics'
import DealsStatisticsFilter from './DealsStatisticsFilter'
import { useDealsStatContext } from '../../context/DealsStatContext';
import Loader from '../../common/Loader/index'
import { HiOutlineRefresh } from "react-icons/hi";
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { BsFunnelFill } from "react-icons/bs";
import { FaDownload, FaUndo } from "react-icons/fa";
import ModalSideRight from '../../components/Modals/ModalSideRight'

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'Deals statistics', link: '/deals-statistics' }
];

const TransactionStatistics: React.FC = () => {
  const {
    statistics,
    isLoading,
    getTransactionStatistics,
    totalCount,
    handlePageChange,
    currentPage,
    pageSize,
    setStatistics,
    resetFilter,
    startDate,
    endDate,
    selectedApiKeys,
    selectedBots,
    selectedSymbols,
  } = useDealsStatContext();

  const [isModalFilter, setIsModalFilter] = useState(false);

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const handleRefresh = () => {
    getTransactionStatistics(currentPage, pageSize, startDate, endDate, selectedApiKeys, selectedBots, selectedSymbols);
  };

  const handleResetFilter = () => {
    resetFilter();
  };

  const downloadFile = async () => {
    let acc = selectedApiKeys
    if (selectedApiKeys.length == 0 && selectedBots.length > 0) {
      acc = selectedBots
    }
    try {
      fetch(`/api/api_keys/positions/history/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify({
          from: startDate,
          to: endDate,
          accounts: acc,
          symbols: selectedSymbols
        })
      })
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.download = 'deals-report.xlsx';
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link)
        })
    } catch (error) {
      console.log("Errr data", error)
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <div className='flex flex-wrap gap-4 mb-4'>
        <BtnPrimary onClick={handleRefresh}>
          <HiOutlineRefresh /> Refresh
        </BtnPrimary>
        <BtnPrimary onClick={toggleModalFilter}>
          <BsFunnelFill /> Filter
        </BtnPrimary>
        <BtnPrimary onClick={handleResetFilter}>
          <FaUndo /> Reset Filter
        </BtnPrimary>
        <BtnPrimary onClick={downloadFile}>
          <FaDownload /> Download data
        </BtnPrimary>
      </div>

      <ModalSideRight
        isOpen={isModalFilter}
        toggleModal={toggleModalFilter}
        title="FILTER"
        content={
          <DealsStatisticsFilter toggleModal={toggleModalFilter} />
        }
      />


      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5 mt-2">
        <div className="col-span-12">
          <TableDealsStatistics
            statistics={statistics}
            updateStatistics={getTransactionStatistics}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            setStatistics={setStatistics} />
        </div>
      </div>
    </>
  );
};

export default TransactionStatistics;