import { jwtDecode } from 'jwt-decode';
import { refreshTokens } from './refreshTokens';

let lastActivityTime = Date.now();
let refreshInterval: NodeJS.Timeout | null = null;
let isUserActive = true;

export const startProactiveTokenRefreshing = () => {
  let timeToRefresh = 5 * 60 * 1000; // 5 минут 

  // console.log(`Запуск проактивного обновления токена через ${timeToRefresh / 1000} секунд`);
  // console.log("timeToRefresh", timeToRefresh);

  const refreshTokensInterval = async () => {
    if (isUserActive) {
      // console.log('Пользователь активен, проверка авторизации...');
      await refreshTokens();
    } else {
      // console.log('Пользователь неактивен, остановка проактивного обновления токена...');
      if (refreshInterval) {
        clearInterval(refreshInterval);
        refreshInterval = null;
      }
    }
  };

  refreshInterval = setInterval(refreshTokensInterval, timeToRefresh);

  // События для определения активности пользователя
  const activityEvents = [
    'mousemove',
    'keydown',
    'touchstart',
    'scroll',
    'click',
    'touchend',
    'touchmove',
    'wheel',
  ];

  activityEvents.forEach(event => {
    document.addEventListener(event, () => {
      const now = Date.now();
      if (now - lastActivityTime > 1000) { // Задержка в 1 секунду
        isUserActive = true;
        // console.log('Обнаружена активность пользователя, сброс таймера бездействия...');
        resetInactivityTimer();

        // Перезапуск проактивного обновления токена, если оно еще не запланировано
        if (!refreshInterval) {
          refreshInterval = setInterval(refreshTokensInterval, timeToRefresh);
        }
      }
      lastActivityTime = now;
    });
  });

  let inactivityTimer: NodeJS.Timeout | null = null; 

  const resetInactivityTimer = () => {
    if (inactivityTimer) {
      clearTimeout(inactivityTimer);
    }
    inactivityTimer = setTimeout(() => {
      isUserActive = false;
      // console.log('Пользователь неактивен в течение 1 минуты, остановка проактивного обновления токена...');
      if (refreshInterval) {
        clearInterval(refreshInterval);
        refreshInterval = null;
      }
    }, 1 * 60 * 1000); // 1 минута бездействия
  };

  resetInactivityTimer();
};