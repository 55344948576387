import React from 'react';
import { BotsProvider } from '../../../context/BotsAllContext';
import BotsAllContent from './BotsAllContent';

const ApiKeys: React.FC = () => {
  return (
    <BotsProvider>
      <BotsAllContent />
    </BotsProvider>
  );
};

export default ApiKeys;