import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

// Создаем стилизованную кнопку без фона и границ
const TransparentButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  boxShadow: 'none',
  padding: '0px',
  minWidth: 'auto',
}));

// Функция для сокращения имени до одной строки с троеточием
const truncateName = (name: string, maxLength: number) => {
  if (name.length > maxLength) {
    return name.substring(0, maxLength - 3) + '...';
  }
  return name;
};

interface BotTooltipProps {
  bot: {
    name: string;
  };
}

const CustomTooltip: React.FC<BotTooltipProps> = ({ bot }) => {
  const maxLength = 20; // Максимальная длина имени до сокращения
  const truncatedName = truncateName(bot.name, maxLength);
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      title={bot.name}
      placement="top"
      arrow
      open={open}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      enterDelay={500}
      leaveDelay={200}
      TransitionProps={{ timeout: 600 }}
      classes={{
        tooltip: 'bg-gray-800 dark:bg-gray-200 text-white',
        arrow: 'text-gray-800 dark:text-gray-200',
      }}
    >
      <TransparentButton
        variant="contained"
        onClick={handleTooltipOpen}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        className="text-text-white dark:text-white"
      >
        <p className="text-[#637381] dark:text-bodydark">{truncatedName}</p>
      </TransparentButton>
    </Tooltip>
  );
};

export default CustomTooltip;