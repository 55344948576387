import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { ApiResponse, AuthResult } from '../types/api';
import { jwtDecode } from 'jwt-decode';
import { startProactiveTokenRefreshing } from '../utils/startProactiveTokenRefreshing';

export const useAuth = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const login = async (email: string, password: string) => {
    setError(null);
    setIsLoading(true);
    console.log('Вход с email:', email);
    try {
      const response = await axios.post<ApiResponse<AuthResult>>('/api/auth/signin', { email, password });

      if (response.data.code === 200 && response.data.result) {
        const accessToken = response.data.result.accessToken;
        const decodedToken = jwtDecode<{ sub: string; role: string, userAgent: string, exp: number }>(accessToken);

        const userData = {
          id: decodedToken.sub,
          email: email,
          role: decodedToken.role,
          userAgent: decodedToken.userAgent,
        };

        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', response.data.result.refreshToken);
        // console.log('Вход выполнен успешно, перенаправление на /users');

        // Искусственная задержка после получения ответа от сервера
        await new Promise(resolve => setTimeout(resolve, 1000));

        navigate('/users');

        // Запуск proactive token refreshing
        startProactiveTokenRefreshing();
      } else {
        setError('Incorrect email or password');
        toast.error('Incorrect email or password');
        // console.log('Вход не выполнен, неверный email или пароль');
      }
    } catch (error: any) {
      const textError = error.response.data.error[0].toUpperCase() + error.response.data.error.slice(1)
      setError(textError);
      toast.error(textError);
      console.log('Вход не выполнен, ошибка:', textError);
    } finally {
      setIsLoading(false);
    }
  };

  return { login, error, isLoading };
};