// TablePositions.tsx
import React, { useEffect, useState } from 'react';
import { AccountInfo, Position } from "../../types/manualTrade";
import NotFound from '../../common/NotFound';
import { formatAmount } from '../../utils/formatAmount';
import { formatCurrency } from '../../utils/formatCurrency';
import BtnPrimary from "../../components/Buttons/BtnPrimary";
import ModalCenter from '../../components/Modals/ModalCenter';
import ClosePositions from "./ClosePositions";
import { BsFillLockFill } from "react-icons/bs";
import { toast } from 'sonner';
import { useManualTradingContext } from '../../context/ManualTradingContext';

interface TableProps {
  positions: Position[] | undefined;
}

const TablePositions: React.FC<TableProps> = ({ positions }) => {
  const [isModalClose, setIsModalClose] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState<Position | null>(null);
  const [selectedAmountDefault, setSelectedAmountDefault] = useState<string>('');
  const [selectedSymbolDefault, setSelectedSymbolDefault] = useState<string>('');
  const { handleClosePosition } = useManualTradingContext();

  const toggleModalClose = (position: Position, amountDefault: string, symbol: string) => {
    setSelectedPosition(position);
    setSelectedAmountDefault(amountDefault);
    setSelectedSymbolDefault(symbol);
    setIsModalClose(!isModalClose);
  };

  const handleClosePositionWrapper = async (position: string, amount: number, symbol: string) => {
    const result = await handleClosePosition(position, amount, symbol);
    if (result) {
      toast.success('Position closed successfully');
    } else {
      toast.error('Failed to close position');
    }
    setIsModalClose(false);
  };

  const getProfitClass = (profit: number): string => {
    if (profit === 0) {
      return 'text-gray-500';
    } else if (profit < 0) {
      return 'text-red-500';
    } else {
      return 'text-green-500';
    }
  };

  useEffect(() => {
    // Обновляем таблицу при изменении positions
    console.log("positions1:", positions);
  }, [positions]);

  return (
    <>
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto">
          <div className="min-w-[1170px]">
            <div className="grid grid-cols-12 gap-4 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] text-center">POSITION</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] text-center">AMOUNT</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] text-center">NOTIONAL</h5>
              </div>
              <div className="col-span-3">
                <h5 className="font-medium text-[#637381] text-center">UNREALIZED PROFIT</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-center">ENTRY PRICE</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-center">ACTIONS</h5>
              </div>
            </div>

            <div className="bg-white dark:bg-boxdark">
              {!positions || positions.length === 0 ? (
                <NotFound />
              ) : (
                positions.map((position, key) => (
                  <div className="grid grid-cols-12 gap-4 border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11" key={key}>
                    <div className="col-span-2 flex items-center justify-center">
                      <p className={`inline-flex rounded-full border py-1 px-3 text-sm font-medium hover:opacity-80 uppercase ${position.positionSide === 'LONG' ? 'border-[#3CA745] text-[#3CA745]' : 'border-[#FF5733] text-[#FF5733]'}`}>{position.positionSide}</p>
                    </div>
                    <div className="col-span-1 items-center">
                      <p className="text-center">{position.symbol} {formatAmount(position.availableAmt)}</p>
                    </div>
                    <div className="col-span-2 items-center">
                      <p className="text-[#637381] text-center">{formatCurrency(position.positionValue)}</p>
                    </div>
                    <div className="col-span-3 items-center">
                      <p className={`${getProfitClass(Number(position.unrealizedProfit))} text-center`}>{formatCurrency(position.unrealizedProfit)}</p>
                    </div>
                    <div className="col-span-2 items-center">
                      <p className={`text-[#637381] text-center`}>{formatCurrency(position.avgPrice)}</p>
                    </div>
                    <div className="col-span-1 flex items-center justify-center">
                      <BtnPrimary style="red" onClick={() => toggleModalClose(position, position.availableAmt, position.symbol)}>
                        <BsFillLockFill /> Close
                      </BtnPrimary>

                      {selectedPosition && (
                        <ModalCenter
                          isOpen={isModalClose}
                          toggleModal={() => toggleModalClose(selectedPosition, selectedAmountDefault, selectedSymbolDefault)}
                          content={
                            <ClosePositions
                              positions={selectedPosition.positionSide}
                              amountDefault={selectedAmountDefault}
                              symbolDefault={selectedSymbolDefault}
                              onSave={handleClosePositionWrapper}
                              toggleModal={() => toggleModalClose(selectedPosition, selectedAmountDefault, selectedSymbolDefault)}
                            />
                          }
                        />
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TablePositions;