// ClosePositions.tsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Toaster, toast } from 'sonner';
import { BsPlusCircleFill, BsXCircleFill } from "react-icons/bs";
import SimpleSelect from '../../components/Forms/Select/SimpleSelect';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import InputField from '../../components/Forms/Inputs/InputField';
import api from '../../utils/api';

interface ClosePositionsProps {
  positions: string;
  amountDefault: string;
  symbolDefault: string;
  toggleModal: () => void;
  onSave: (position: string, amount: number, symbol: string) => Promise<void>;
}

const ClosePositions: React.FC<ClosePositionsProps> = ({ positions, amountDefault, symbolDefault, toggleModal, onSave }) => {
  const [amount, setAmount] = useState(amountDefault);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAmount(value);
  };

  const handleClosePosition = async () => {
    await onSave(positions, Number(amount), symbolDefault);
    toggleModal();
  };

  return (
    <>
      <div>
        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
          Choose positions <span className='uppercase'>{positions}</span>
        </h3>
        <InputField
          label="Amount"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Amount"
          className="mb-4"
        />
        <div className='flex items-center justify-between gap-4 mt-auto'>
          <BtnPrimary
            className='w-full'
            onClick={handleClosePosition}
          >
            <BsPlusCircleFill /> Close <span className='uppercase'>{positions}</span>
          </BtnPrimary>
        </div>
      </div>
    </>
  );
};

export default ClosePositions;