import React, { useEffect, useRef } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { IoCalendarClear } from "react-icons/io5";

interface DatePickerProps {
  options?: flatpickr.Options.Options;
  ref?: React.RefObject<HTMLInputElement>;
}

const DatePicker: React.FC<DatePickerProps> = ({ options = {} }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      flatpickr(inputRef.current, {
        ...options,
        prevArrow:
          '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
        nextArrow:
          '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      });
    }
  }, [options]);

  return (
    <div className="relative">
      <input
        ref={inputRef}
        autoComplete="off"
        className="form-datepicker w-full rounded-lg border-[1.5px] border-stroke bg-transparent p-3 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
        placeholder="mm/dd/yyyy"
        data-class="flatpickr-right"
        style={{ minWidth: '270px' }}
      />
      <div className="pointer-events-none absolute inset-0 left-auto right-4 flex items-center">
        <IoCalendarClear />
      </div>
    </div>
  );
};

export default DatePicker;