import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'sonner';
import { BsRobot, BsTrash, BsPencil, BsPlusCircleFill, BsXCircleFill } from "react-icons/bs";
import ModalCenter from '../../components/Modals/ModalCenter';
import InputField from '../../components/Forms/Inputs/InputField';
import { KEY } from '../../types/key';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import ContentDelete from '../../components/Modals/ContentConfirm';
import api from '../../utils/api'
import SimpleSelect from '../../components/Forms/Select/SimpleSelect';
import { getCurrentUser } from "../../utils/getCurrentUser"
import TextareaField from '../../components/Forms/Inputs/TextareaField'
import { IoCopy } from "react-icons/io5";

enum KeyActions {
  Add = "add",
  Edit = "edit",
}

const exchangers = [
  {
    value: "Binance",
    text: "Binance",
  },
  {
    value: "BingX",
    text: "BingX",
  },
  {
    value: "Bybit",
    text: "Bybit",
  },
  {
    value: "Gate",
    text: "Gate",
  },
  {
    value: "Mexc",
    text: "Mexc",
  },
  {
    value: "Bitget",
    text: "Bitget",
  },
];

const markets = [
  {
    value: "Futures",
    text: "Futures",
  },
  {
    value: "Spot",
    text: "Spot",
  },
]

const marketsOnlySpot = [
  {
    value: "Spot",
    text: "Spot",
  },
]

interface KeyProps {
  toggleModal: () => void;
  keyItem?: KEY;
  updateKeys?: () => void;
  onSave?: (result: boolean) => void;
  action: string;
}

const Key: React.FC<KeyProps> = ({ toggleModal, keyItem, updateKeys, onSave, action }) => {
  const [name, setName] = useState(keyItem?.name || '');
  const [exchanger, setExchanger] = useState('BingX')
  const [market, setMarket] = useState('Futures')
  const [pass, setPass] = useState('')
  const [subAccId, setSubAccId] = useState(keyItem?.subAccountId || '');
  const [publicKey, setSetPublicKey] = useState(keyItem?.public_key || '');
  const [secretKey, setSecretKey] = useState(keyItem?.private_key || '');

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handlePassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPass(e.target.value);
  };

  const handleSubAccIdhange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubAccId(e.target.value);
  };

  const handlePublicKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSetPublicKey(e.target.value);
  };


  const handleSecretKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSecretKey(e.target.value);
  };

  const handleExchangerChange = (value: string) => {
    setExchanger(value);
  };

  const handleMarketChange = (value: string) => {
    setMarket(value);
  };

  const handleAddKey = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    if (name === '' || exchanger === '' || market === '' || publicKey === '' || secretKey === '') {
      toast.error('Please fill all fields form');
      return;
    }

    // const keyData = {
    //   exchangers,
    //   name,
    //   market,
    //   subAccId,
    //   publicKey,
    //   secretKey
    // };

    const user = getCurrentUser()
    console.log("user: ", user)

    const keyData_time = {
      // user_id: user?.id,
      name: name,
      exchange_name: exchanger,
      public_key: publicKey,
      private_key: secretKey,
      market: market,
      memo: pass
    }

    try {
      const response = await api.post(`/api/api_keys`, keyData_time);
      if (response.data.code === 200 && response.data.result) {
        updateKeys?.()
        toggleModal();
        onSave?.(true);
      }
    } catch (error: any) {
      const textError = error.response.data.error[0].toUpperCase() + error.response.data.error.slice(1)
      console.log("Error :", error)
      setError(textError);
      onSave?.(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditKey = async (e: React.FormEvent) => {
    e.preventDefault();

    // if (name === '' || subAccId === '') {
    //   toast.error('Please fill all fields form');
    //   return;
    // }

    // const keyData = {
    //   name,
    //   subAccountId: subAccId,
    // };

    const keyData = {
      name: name,
      subAccountId: subAccId,
      memo: "Updated memo",
    }

    try {
      const response = await api.put(`/api/api_keys/${keyItem?.id}`, keyData);
      console.log("Response :", response.data)
      updateKeys?.()
      toggleModal();
      onSave?.(true);
    } catch (error) {
      console.log("Error :", error)
      onSave?.(false);
    }
  }

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      toast.success('Public key copied to clipboard');
    }).catch((error) => {
      toast.error('Failed to copy public key:', error);
    });
  };

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <>
      <form onSubmit={action === KeyActions.Add ? handleAddKey : handleEditKey} className="flex flex-col h-full">
        <div className="flex-grow mb-4">
          {action === KeyActions.Add &&
            <div>
              <SimpleSelect
                label="Exchangers"
                options={exchangers}
                value={exchanger}
                onChange={handleExchangerChange}
                className="w-full mb-4"
              />
              <SimpleSelect
                label="Market"
                options={markets}
                value={market}
                onChange={handleMarketChange}
                className="w-full mb-4"
              />
            </div>
          }

          <InputField
            label="Name"
            value={name}
            onChange={handleNameChange}
            placeholder="Name task"
            className="mb-4"
          />

          {/* <InputField
            label="Sub Account ID"
            value={subAccId}
            onChange={handleSubAccIdhange}
            placeholder="Sub Account ID"
            className="mb-4"
          /> */}

          {action === KeyActions.Edit ?
            <div className="relative">
              <div
                className="absolute top-0 right-0 cursor-pointer"
                onClick={() => handleCopy(publicKey)}
              >
                <IoCopy className='cursor-pointer duration-300 ease-in-out hover:text-primary text-xl' />
              </div>
              <TextareaField
                label="Public key"
                value={publicKey}
                className="mb-4"
                disabled={action === KeyActions.Edit}
              />
            </div>
            :
            <InputField
              label="Public key"
              value={publicKey}
              onChange={handlePublicKeyChange}
              placeholder="Public key"
              className="mb-4"
            // disabled={action === KeyActions.Edit}
            />
          }

          {action === KeyActions.Add &&
            <InputField
              label="Secret key"
              value={secretKey}
              onChange={handleSecretKeyChange}
              placeholder="Secret key"
              className="mb-4"
            />}

          {exchanger == "Bitget" && (
            <InputField
              label="Memo"
              value={pass}
              onChange={handlePassChange}
              placeholder="Memo/Password"
              className="mb-4"
            />
          )}

        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className='flex items-stretch justify-between gap-4 mt-auto'>
          {/* <BtnPrimary
            disabled={action === KeyActions.Add ? 
              name === '' || name.length < 3 
              || exchanger === '' || exchanger.length < 3 
              || market === '' || market.length < 3 
              || publicKey === '' || publicKey.length < 3 
              || secretKey === '' || secretKey.length < 3 
              : name === '' || subAccId === ''}  
            className='w-full'
            type='submit'>
            <BsPlusCircleFill />  {action === KeyActions.Add ? 'Add ' : 'Edit '}key
          </BtnPrimary> */}
          <button
            type="submit"
            className={`w-full rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90 ${!name || !exchanger || !market || !publicKey || !secretKey || isLoading || name.length < 3 || exchanger.length < 3 || market.length < 3 || publicKey.length < 3 || secretKey.length < 3 ? 'cursor-none opacity-50 cursor-no-drop' : 'cursor-pointer'
              }`}
            disabled={action === KeyActions.Add ?
              name === '' || name.length < 3
              || exchanger === '' || exchanger.length < 3
              || market === '' || market.length < 3
              || publicKey === '' || publicKey.length < 3
              || secretKey === '' || secretKey.length < 3
              || isLoading
              : name === '' || subAccId === ''}
          >
            {isLoading ?
              <div className='flex gap-3 items-center justify-center'>
                <span className="animate-spin">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-inside-1_1881_16183" fill="white">
                      <path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z"></path></mask><path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z" stroke="white" stroke-width="14" mask="url(#path-1-inside-1_1881_16183)"></path>
                  </svg>
                </span>
                <p>Loading...</p>
              </div>
              : <div className="flex items-center justify-center gap-4 mt-auto"><BsPlusCircleFill />  {action === KeyActions.Add ? 'Add ' : 'Edit '}key</div>
            }
          </button>
          <BtnPrimary
            className='w-full'
            style="outline"
            onClick={() => {
              toggleModal();
            }}>
            <BsXCircleFill /> Close
          </BtnPrimary>
        </div>
      </form>
    </>
  );
};

export default Key;