export const TimeFrame = [
  {
    value: "1m",
    text: "1m",
  },
  {
    value: "3m",
    text: "3m",
  },
  {
    value: "5m",
    text: "5m",
  },
  {
    value: "15m",
    text: "15m",
  },
  {
    value: "30m",
    text: "30m",
  },
  {
    value: "1h",
    text: "1h",
  },
  {
    value: "1w",
    text: "1w",
  },
]