import Pagination from '../../components/Pagination/Pagination';
import ModalSideRight from '../../components/Modals/ModalSideRight'
import { OrderHistory } from '../../types/key';
import { formatCurrency } from '../../utils/formatCurrency'
import { formatAmount } from  '../../utils/formatAmount'
import { formatDate } from '../../utils/formatDate'
import { KEY } from '../../types/key'
import NotFound from '../../common/Loader/index'

interface HistoryOrdersModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  orderHistory: OrderHistory[];
  orderHistoryPage: number;
  orderHistoryTotalCount: number;
  handleOrderHistoryPageChange: (selectedItem: { selected: number }) => void;
}

const HistoryOrdersModal: React.FC<HistoryOrdersModalProps> = ({
  isOpen,
  toggleModal,
  orderHistory,
  orderHistoryPage,
  orderHistoryTotalCount,
  handleOrderHistoryPageChange,
}) => {
  const orderHistoryPerPage = 10;
  const pageCount = Math.ceil((orderHistoryTotalCount || 0) / orderHistoryPerPage);

  const getProfitClass = (profit: number): string => {
    if (profit === 0) {
      return 'text-gray-500';
    } else if (profit < 0) {
      return 'text-red-500';
    } else {
      return 'text-green-500';
    }
  };

  return (
    <ModalSideRight
      isOpen={isOpen}
      toggleModal={toggleModal}
      title="History Orders"
      content={
        <div className="flex-grow mb-4 flex flex-col gap-2">

          {
            !orderHistory || orderHistory.length === 0 ? (
              <NotFound />
            ) : (
              orderHistory.map((order) => (
                <div key={order.id} className="flex flex-col gap-2 rounded-md border border-stroke p-2.5 shadow-1 dark:border-strokedark dark:bg-[#37404F]">
    
                  <div className="flex justify-between items-center">
                    <div><p className="text-sm font-semibold text-black dark:text-white"> {`${formatAmount(order.origQty)} ${order.symbol} (${formatCurrency(Number(order.origQty) * Number(order.avgPrice))})`}</p></div>
                    <div>
                      <p className={getProfitClass(Number(order.profit))}>{formatCurrency(order.profit)}</p>
                    </div>
                  </div>
    
                  <div className="flex justify-between items-center">
                    <p className={`${order.side === 'BUY' ? 'text-green-700' : 'text-red-500'}`}>{order.side}</p>
                    <p>{order.type}</p>
                  </div>
    
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <div className="col-span-2 flex items-center justify-center">
                        <p className={`inline-flex rounded-full border py-1 px-3 text-sm font-medium uppercase ${order.positionSide === 'LONG' ? 'border-[#3CA745] text-[#3CA745]' : 'border-[#FF5733] text-[#FF5733]'}`}>{order.positionSide}</p>
                      </div>
                      <p>{formatCurrency(order.avgPrice)}</p>
                    </div>
                    <p>{formatDate(order.time)}</p>
                  </div>
    
                  {/* <div className="flex flex-col items-start gap-2">
                    <p className="font-semibold text-black dark:text-white"> {`${order.origQty} ${order.symbol} (${Number(order.origQty) * Number(order.avgPrice)} $)`}</p>
                    <p className={`${order.side === 'BUY' ? 'text-green-700' : 'text-red-500'}`}>{order.side}</p>
                    <div className="col-span-2 flex items-center justify-center">
                      <p className={`inline-flex rounded-full border py-1 px-3 text-sm font-medium uppercase ${order.positionSide === 'LONG' ? 'border-[#3CA745] text-[#3CA745]' : 'border-[#FF5733] text-[#FF5733]'}`}>{order.positionSide}</p>
                    </div>
                  </div> */}
    
                  {/* <div className="flex flex-col items-end gap-2">
                    <p>{order.status}</p>
                    <p className={`${order.type === 'TAKE_PROFIT' ? 'text-green-700' : 'text-red-500'}`}>{order.type}</p>
                    <p>{formatDate(order.time)}</p>
                  </div> */}
                </div>
              ))
            )

          }

          {orderHistory.length > 0 && (
            <Pagination
              pageCount={pageCount}
              onPageChange={handleOrderHistoryPageChange}
              previousLabel={'<'}
              nextLabel={'>'}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
            />
          )}
        </div>
      }
    />
  );
};

export default HistoryOrdersModal;