import React from 'react';
import Logo from '../../images/logo/logo.svg';
import InputEmail from '../../components/Forms/Inputs/InputEmail';
import InputPassword from '../../components/Forms/Inputs/InputPassword';
import { useForm } from '../../hooks/useFormSignIn';
import { useAuth } from '../../hooks/useAuth';
import { Toaster, toast } from 'sonner';

const SignIn: React.FC = () => {
  const { values, errors, handleChange, handleSubmit } = useForm({ email: '', password: '' });
  const { login, error, isLoading } = useAuth();

  const onSubmit = () => {
    login(values.email, values.password);
  };

  return (
    <div className="rounded-lg border shadow-default border-strokedark bg-boxdark w-full max-w-lg mx-4 sm:mx-auto">
      <Toaster position="bottom-center" richColors />
      <div className="flex flex-wrap items-center">
        <div className="w-full border-strokedark p-6 sm:p-11.5">
          <div className='mb-4 flex items-center justify-center'>
            <img src={Logo} alt="Logo" />
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <InputEmail
              name="email"
              value={values.email}
              onChange={handleChange}
              className='mb-4'
            />
            <InputPassword
              name="password"
              className='mb-4'
              value={values.password}
              onChange={handleChange}
              errorForm={!!errors.password}
            />

            {errors.email && <p className="text-red-500 mb-4">{errors.email}</p>}
            {errors.password && <p className="text-red-500 mb-4">{errors.password}</p>}
            {error && <p className="text-red-500 mb-4">{error}</p>}

            <div className="mb-5">
              <button
                type="submit"
                className={`w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90 ${
                  !values.email || !values.password || isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={!values.email || !values.password || isLoading}
              >
                {isLoading ? 
                  <div className='flex gap-3 items-center justify-center'>
                    <span className="animate-spin">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="path-1-inside-1_1881_16183" fill="white">
                        <path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z"></path></mask><path d="M15.328 23.5293C17.8047 22.8144 19.9853 21.321 21.547 19.2701C23.1087 17.2193 23.9686 14.72 23.9992 12.1424C24.0297 9.56481 23.2295 7.04587 21.7169 4.95853C20.2043 2.8712 18.0597 1.32643 15.6007 0.552947C13.1417 -0.220538 10.499 -0.181621 8.0638 0.663935C5.62864 1.50949 3.53049 3.11674 2.07999 5.24771C0.629495 7.37868 -0.096238 9.92009 0.0102418 12.4957C0.116722 15.0713 1.04975 17.5441 2.6712 19.5481L4.96712 17.6904C3.74474 16.1796 3.04133 14.3154 2.96106 12.3737C2.88079 10.432 3.42791 8.51604 4.52142 6.90953C5.61493 5.30301 7.19671 4.09133 9.03255 3.45387C10.8684 2.81642 12.8607 2.78708 14.7145 3.3702C16.5683 3.95332 18.1851 5.1179 19.3254 6.69152C20.4658 8.26514 21.0691 10.1641 21.046 12.1074C21.023 14.0506 20.3748 15.9347 19.1974 17.4809C18.02 19.027 16.3761 20.1528 14.5089 20.6918L15.328 23.5293Z" stroke="white" stroke-width="14" mask="url(#path-1-inside-1_1881_16183)"></path>
                      </svg>
                    </span>
                    <p>Loading...</p>
                  </div> 
                  : 'Sign in'}
              </button>

            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;