export const formatCurrency = (value: number | string | undefined): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 8,
  });
  if (typeof value === 'undefined') {
    return formatter.format(0)
  }
  const numValue = typeof value === 'string' ? parseFloat(value) : value;

  return formatter.format(numValue);
};