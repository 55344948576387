import React, { useState } from 'react';
import { BsFillTrashFill, BsFillPencilFill, BsChevronDown, BsChevronUp, BsPlusCircleFill, BsClockHistory } from "react-icons/bs";
import { IoSettings } from "react-icons/io5";
import { GrDetach } from "react-icons/gr";
import { Toaster, toast } from 'sonner';
import NotFound from '../../../common/NotFound';
import Switcher from "../../../components/Switchers/Switcher";
import { Db_Bots } from '../../../types/botsAll';
import ModalSideRight from '../../../components/Modals/ModalSideRight';
import Bot from './Bot';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../components/Pagination/Pagination';
import ModalCenter from '../../../components/Modals/ModalCenter';
import ContentConfirm from '../../../components/Modals/ContentConfirm';
import { formatCurrency } from '../../../utils/formatCurrency';
import BtnPrimary from '../../../components/Buttons/BtnPrimary';
import AttachBot from './AttachDetachBot';
import HistoryOrdersModal from '../../HistoryOrders/HistoryOrdersModal'
import { RiRadioButtonLine } from "react-icons/ri";
import { PiTrademarkFill } from "react-icons/pi";
import { formatAmount } from '../../../utils/formatAmount'
import BotTooltip from '../../../components/CustomTooltip/CustomTooltip'
import { FaComment } from "react-icons/fa";
import CommentForBot from './CommentForBot';
import { useBotsContext } from '../../../context/BotsAllContext';
import { InfoBingX } from "../../../types/key";


interface BotsAllProps {
  bots: Db_Bots[];
  updateBots: (page: number) => void;
  totalCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  currentPage: number;
}

const TableBotsAll: React.FC<BotsAllProps> = ({ bots, updateBots, totalCount, onPageChange, currentPage }) => {
  const {
    handleActiveChange,
    isModalAttachKeyOpen,
    toggleModalAttachKey,
    handleAttachKey,
    botsAll,
    isModalEditOpen,
    toggleModalEdit,
    selectedBot,
    handleDetach,
    selectedKey,
    orderHistory,
    toggleModalDetach,
    handleOrderHistoryPageChange,
    handleHistoryOrdersKey,
    orderHistoryTotalCount,
    isModalDetach,
    handleEditBot,
    handleSaveUpdate,
    isModalConfirmOpen,
    handleApiKeyDetach,
    toggleModalConfirm,
    handleActivateConfirm,
    setConfirmBotUuid,
    switcherStates,
    handleCommentBot,
    orderHistoryPage,
    setSwitcherStates,
    setSelectedBot,
    isModalHistoryOrders,
    toggleModalHistoryOrders,
    isModalCommentOpen,
    toggleModalComment
  } = useBotsContext();

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleNavigateToBotSettings = (bot: Db_Bots) => {
    if (bot?.api?.name) {
      navigate(`/bot/${bot?.uuid}`);
    } else {
      toast.error('First, bind the API key to the bot')
    }
  };

  const handleNavigateToManualTrading = (keyId: string, symbol: string, exchange_name: string) => {
    if (!symbol || symbol == "") {
      let sym = "BTC-USDT"
      if (exchange_name.toUpperCase() == "BINGX") {
        sym = "BTC-USDT"
      } else if (exchange_name.toUpperCase() == "BYBIT") {
        sym = "BTCUSDT"
      } else if (exchange_name.toUpperCase() == "GATE") {
        sym = "BTC_USDT"
      } else if (exchange_name.toUpperCase() == "MEXC") {
        sym = "BTCUSDT"
      } else if (exchange_name.toUpperCase() == "BITGET") {
        sym = "BTCUSDT"
      } else if (exchange_name.toUpperCase() == "BINANCE") {
        sym = "BTCUSDT"
      }
      navigate(`/manual-trading/${keyId}/${sym}`);
    } else {
      navigate(`/manual-trading/${keyId}/${symbol}`);
    }


  };

  const pageCount = Math.ceil((totalCount || 0) / 10);

  const toggleRowExpansion = (id: string) => {
    setExpandedRows(prevExpandedRows =>
      prevExpandedRows.includes(id)
        ? prevExpandedRows.filter(rowId => rowId !== id)
        : [...prevExpandedRows, id]
    );
  };

  const handleSwitcherChange = async (bot: Db_Bots, isActive: boolean) => {
    if (!bot.api.name) {
      toast.error('First, bind the key to the bot')
      return
    }

    const botResult = botsAll.find(bot => bot.uuid === bot.uuid);
    if (botResult) {
      setSelectedBot(botResult);
    }
    setConfirmBotUuid(bot.uuid);
    toggleModalConfirm();
  };

  const handleDetachConfirm = (botId: string) => {
    setSelectedBot(botsAll.find(bot => bot._id === botId) || null);
    toggleModalConfirm();
  };

  // Функция для расчета суммы позиций и количества ордеров для выбранного символа
  const calculatePositionsAndOrders = (bot: Db_Bots) => {
    if (!bot || !bot?.settings?.symbol) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    }

    let positions = []
    let openOrder = []
    if (bot?.api?.exchange_name.toUpperCase() == "BINGX" && !bot?.api?.infoBingX) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    } else if (bot?.api?.exchange_name.toUpperCase() == "BYBIT" && !bot?.api?.infoBybit) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    } else if (bot?.api?.exchange_name.toUpperCase() == "GATE" && !bot?.api?.infoGate) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    } else if (bot?.api?.exchange_name.toUpperCase() == "MEXC" && !bot?.api?.infoMexc) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    } else if (bot?.api?.exchange_name.toUpperCase() == "BITGET" && !bot?.api?.infoBitget) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    } else if (bot?.api?.exchange_name.toUpperCase() == "BINANCE" && !bot?.api?.infoBinance) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    }

    if (bot?.api?.exchange_name.toUpperCase() == "BYBIT") {
      positions = bot?.api?.infoBybit.positions || [];
      openOrder = bot?.api?.infoBybit.openOrder || [];
    } else if (bot?.api?.exchange_name.toUpperCase() == "GATE") {
      positions = bot?.api?.infoGate.positions || [];
      openOrder = bot?.api?.infoGate.openOrder || [];
    } else if (bot?.api?.exchange_name.toUpperCase() == "MEXC") {
      positions = bot?.api?.infoMexc.positions || [];
      openOrder = bot?.api?.infoMexc.openOrder || [];
    } else if (bot?.api?.exchange_name.toUpperCase() == "BITGET") {
      positions = bot?.api?.infoBitget.positions || [];
      openOrder = bot?.api?.infoBitget.openOrder || [];
    } else if (bot?.api?.exchange_name.toUpperCase() == "BINANCE") {
      positions = bot?.api?.infoBinance.positions || [];
      openOrder = bot?.api?.infoBinance.openOrder || [];
    } else {
      positions = bot?.api?.infoBingX.positions || [];
      openOrder = bot?.api?.infoBingX.openOrder || [];
    }


    const filteredPositions = positions.filter(position => position.symbol === bot?.settings?.symbol);
    const filteredOpenOrders = openOrder.filter(order => order.symbol === bot?.settings?.symbol);

    const totalPositionValue = filteredPositions.reduce((sum, position) => sum + parseFloat(position.positionValue || '0'), 0);
    const openOrdersCount = filteredOpenOrders.length;

    return { totalPositionValue, openOrdersCount };
  };



  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto">
          <div className="min-w-[1170px]">

            <div className="grid grid-cols-11 gap-2 bg-[#F9FAFB] px-1 py-5 dark:bg-meta-4">
              <div className="col-span-3 flex justify-center items-center">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
              </div>
              {/* <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIVE</h5>
              </div> */}
              {/* <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">DATE</h5>
              </div> */}
              <div className="col-span-3">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">TYPE</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">API</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">POS/ORD</h5>
              </div>
              <div className="col-span-2 flex justify-center items-center">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIONS</h5>
              </div>
            </div>

            <div className="bg-white dark:bg-boxdark">

              {bots && bots.length === 0 ? (
                <NotFound />
              ) : (bots && bots.map((bot, key) => {
                const isExpanded = expandedRows.includes(bot._id);
                const switcherState = switcherStates[bot.uuid] !== undefined ? switcherStates[bot.uuid] : bot.active;

                const currentTime = Date.now();
                const timeDifference = currentTime - new Date(bot.dateConnect).getTime();
                const isOnline = timeDifference <= 10000;

                const { totalPositionValue, openOrdersCount } = calculatePositionsAndOrders(bot);

                const botName = {
                  name: bot.name,
                };

                let accountInfo: InfoBingX
                if (bot?.api?.exchange_name.toUpperCase() == "BYBIT") {
                  accountInfo = bot?.api?.infoBybit;
                } else if (bot?.api?.exchange_name.toUpperCase() == "GATE") {
                  accountInfo = bot?.api?.infoGate;
                } else if (bot?.api?.exchange_name.toUpperCase() == "MEXC") {
                  accountInfo = bot?.api?.infoMexc;
                } else if (bot?.api?.exchange_name.toUpperCase() == "BITGET") {
                  accountInfo = bot?.api?.infoBitget;
                } else if (bot?.api?.exchange_name.toUpperCase() == "BINANCE") {
                  accountInfo = bot?.api?.infoBinance;
                } else {
                  accountInfo = bot?.api?.infoBingX;
                }

                return (
                  <div className="border-t border-[#EEEEEE] dark:border-strokedark" key={key}>
                    <div className="grid grid-cols-11 gap-2 px-2 py-5">
                      <div className="col-span-3">
                        {/* <div className='flex items-left gap-1 relative'> */}
                        <div className="flex gap-3 items-left relative">
                          {/* <p className="text-[#637381] dark:text-bodydark">{currentPage * pageCount + key + 1}</p> */}
                          <RiRadioButtonLine
                            className={`${isOnline ? 'text-green-500' : 'text-red-500'}`}
                          />
                          <BotTooltip bot={botName} />
                          <Switcher
                            isActive={switcherState}
                            onChange={() => handleSwitcherChange(bot, switcherState)}
                            id={`isActiveBot-${bot.uuid}`}
                          />

                          <ModalCenter
                            isOpen={isModalConfirmOpen}
                            toggleModal={toggleModalConfirm}
                            content={
                              <ContentConfirm
                                toggleModal={toggleModalConfirm}
                                text="Are you sure you want to change the bot status?"
                                onHandle={handleActivateConfirm}
                                updateItemsPagination={() => updateBots(currentPage)}
                              />
                            }
                          />
                          <>
                            {bot?.active ?
                              bot?.dateActive === 0 ? "------" : new Date(bot.dateActive).toLocaleString()
                              :
                              bot?.dateStop === 0 ? "------" : new Date(bot.dateStop).toLocaleString()
                            }
                          </>
                        </div>
                      </div>

                      {/* <div className="col-span-1 flex items-start gap-2">
                        <Switcher
                          isActive={switcherState}
                          onChange={() => handleSwitcherChange(bot, switcherState)}
                          id={`isActiveBot-${bot.uuid}`}
                        />

                        <ModalCenter
                          isOpen={isModalConfirmOpen}
                          toggleModal={toggleModalConfirm}
                          content={
                            <ContentConfirm
                              toggleModal={toggleModalConfirm}
                              text="Are you sure you want to change the bot status?"
                              onHandle={handleActivateConfirm}
                              updateItemsPagination={() => updateBots(currentPage)}
                            />
                          }
                        />
                      </div> */}

                      {/* <div className="col-span-1 flex items-start">
                        {bot?.active ?
                          bot?.dateActive === 0 ? "------" : new Date(bot.dateActive).toLocaleString()
                          :
                          bot?.dateStop === 0 ? "------" : new Date(bot.dateStop).toLocaleString()
                        }
                      </div> */}

                      <div className='col-span-3 flex items-start gap-2'>
                        <div>
                          <FaComment
                            className={`${bot?.comment && 'text-primary'} cursor-pointer duration-300 ease-in-out hover:text-primary text-xl`}
                            onClick={() => { handleCommentBot(bot) }}
                          />
                        </div>

                        <ModalSideRight
                          isOpen={isModalCommentOpen}
                          toggleModal={toggleModalComment}
                          title="COMMENT FOR BOT"
                          content={
                            selectedBot && (
                              <CommentForBot
                                updateBots={() => updateBots(currentPage)}
                                toggleModal={toggleModalComment}
                                uuidBot={selectedBot.uuid}
                                currentPage={currentPage}
                                commentDefault={selectedBot.comment}
                              />
                            )
                          }
                        />

                        {bot.settings?.strategy ?
                          <div className={`flex flex-col items-start justify-start overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-96' : 'max-h-18'}`}>
                            <p className='text-[#637381] dark:text-bodydark'>{`${bot.settings?.strategy} ${bot.settings?.symbol}`}</p>
                            {/* <p className='text-[#637381] dark:text-bodydark'>{`${bot.settings?.symbol}`}</p> */}
                            <p className={`text-[#637381] dark:text-bodydark whitespace-normal`}>{`${bot?.comment}`}</p>

                          </div>
                          :
                          <div className={`flex flex-col items-start justify-start overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-96' : 'max-h-18'}`}>
                            <p className='text-[#637381] dark:text-bodydark'>--------</p>
                            <p className={`text-[#637381] dark:text-bodydark whitespace-normal`}>{`${bot?.comment}`}</p>
                          </div>

                        }
                      </div>

                      <div className={`col-span-2 flex flex-col items-start justify-start overflow-hidden transition-all duration-300`}>
                        {bot?.api?.name ?
                          <>
                            <div className='flex items-center gap-2'>
                              {bot.api.name &&
                                <button
                                  className='flex items-center gap-2 transition duration-300 ease-in-out rounded-lg py-1 px-1 font-medium hover:bg-opacity-90 bg-primary text-white w-auto'
                                  onClick={() => handleDetach(bot)}
                                >
                                  <GrDetach />
                                </button>
                              }
                              <ModalCenter
                                isOpen={isModalDetach}
                                toggleModal={toggleModalDetach}
                                content={
                                  selectedBot && (
                                    <ContentConfirm
                                      toggleModal={toggleModalDetach}
                                      text={`Are you sure you want to connect the ${bot?.name} bot?`}
                                      onHandle={handleApiKeyDetach}
                                      updateItemsPagination={() => updateBots(currentPage)}
                                    />
                                  )
                                }
                              />
                              <p className="text-[#637381] dark:text-bodydark font-bold">{bot?.api?.name}</p>
                            </div>
                            <p className="text-[#637381] dark:text-bodydark">Wallet balance: {formatCurrency(accountInfo?.accountFuture?.balance)}</p>
                            <div className={` flex flex-col items-start justify-start overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-96' : 'max-h-6'}`}>
                              <p className="text-[#637381] dark:text-bodydark">Available Balance: {formatCurrency(parseFloat(accountInfo?.accountFuture?.balance) - parseFloat(accountInfo?.accountFuture?.usedMargin))}</p>
                              <p className="text-[#637381] dark:text-bodydark">Used Margin: {formatCurrency(accountInfo?.accountFuture?.usedMargin)}</p>
                              <p className="text-[#637381] dark:text-bodydark">Unrealized Profit: {formatCurrency(accountInfo?.accountFuture?.unrealizedProfit)}</p>
                            </div>
                            {/* {isExpanded && (
                              <div>
                                <p className="text-[#637381] dark:text-bodydark">Available Balance: {formatCurrency(parseFloat(bot.api?.infoBingX?.accountFuture?.balance) - parseFloat(bot.api?.infoBingX?.accountFuture?.usedMargin))}</p>
                                <p className="text-[#637381] dark:text-bodydark">Used Margin: {formatCurrency(bot.api?.infoBingX?.accountFuture?.usedMargin)}</p>
                                <p className="text-[#637381] dark:text-bodydark">Unrealized Profit: {formatCurrency(bot.api?.infoBingX?.accountFuture?.unrealizedProfit)}</p>
                              </div>
                            )} */}
                          </> :
                          <>
                            <BtnPrimary
                              className='w-auto'
                              onClick={() => {
                                setSelectedBot(bot);
                                toggleModalAttachKey();
                              }}
                            >
                              <BsPlusCircleFill /> Attach API Key
                            </BtnPrimary>

                            <ModalCenter
                              isOpen={isModalAttachKeyOpen}
                              toggleModal={toggleModalAttachKey}
                              content={
                                selectedBot && (
                                  <AttachBot
                                    bot={selectedBot}
                                    onAttach={(result) => {
                                      handleAttachKey(result);
                                      updateBots(currentPage);
                                    }}
                                    onClose={toggleModalAttachKey}
                                  />
                                )
                              }
                            />
                          </>
                        }
                      </div>

                      <div className={`col-span-1 flex flex-col items-start overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-96' : 'max-h-12'}`}>
                        {bot?.apiID != "000000000000000000000000" ?
                          <>
                            <p className="text-[#637381] dark:text-bodydark">{`${formatCurrency(totalPositionValue)} / ${openOrdersCount}`} </p>
                            {isExpanded &&
                              (accountInfo?.positions.map((pos, key) => {
                                if (pos.symbol != bot?.settings?.symbol) {
                                  return null
                                }
                                return (
                                  <div className='mt-2'>
                                    <p className="text-[#637381] dark:text-bodydark">{pos.positionSide}: {formatAmount(pos.positionAmt)} ({formatCurrency(pos.positionValue)})</p>
                                    <p className="text-[#637381] dark:text-bodydark">Unrealized Profit: {formatCurrency(pos.unrealizedProfit)}</p>
                                    <p className="text-[#637381] dark:text-bodydark">AVG Price: {formatCurrency(pos.avgPrice)}</p>
                                    <p className="text-[#637381] dark:text-bodydark">Risk Rate: {pos.riskRate}</p>
                                  </div>
                                )
                              }))}
                          </> :
                          <p className='text-[#637381] dark:text-bodydark'>--------</p>
                        }
                      </div>
                      <div className="col-span-2 flex justify-end gap-4">
                        {
                          bot?.api?.subAccountId &&
                          <>
                            <BsClockHistory
                              className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                              onClick={() => {
                                handleHistoryOrdersKey(bot.api.subAccountId)
                              }}
                            />

                            <ModalSideRight
                              isOpen={isModalHistoryOrders}
                              toggleModal={toggleModalHistoryOrders}
                              title="History Orders"
                              content={
                                <HistoryOrdersModal
                                  isOpen={isModalHistoryOrders}
                                  toggleModal={toggleModalHistoryOrders}
                                  orderHistory={orderHistory}
                                  orderHistoryPage={orderHistoryPage}
                                  orderHistoryTotalCount={orderHistoryTotalCount}
                                  handleOrderHistoryPageChange={handleOrderHistoryPageChange}
                                />
                              }
                            />
                          </>
                        }

                        {bot?.apiID != "000000000000000000000000" &&
                          <PiTrademarkFill
                            className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                            onClick={() => handleNavigateToManualTrading(bot?.apiID, bot?.settings?.symbol, bot?.api?.exchange_name)}
                          />
                        }

                        <BsFillPencilFill
                          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                          onClick={() => handleEditBot(bot)}
                        />

                        <ModalSideRight
                          isOpen={isModalEditOpen}
                          toggleModal={toggleModalEdit}
                          title="EDIT BOT"
                          content={
                            selectedBot && (
                              <Bot
                                toggleModal={toggleModalEdit}
                                item={selectedBot}
                                onSave={handleSaveUpdate}
                                updateBots={() => updateBots(currentPage)} />
                            )
                          } />

                        <IoSettings
                          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                          onClick={() => handleNavigateToBotSettings(bot)}
                        />

                        {isExpanded ? (
                          <BsChevronUp
                            className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                            onClick={() => toggleRowExpansion(bot._id)}
                          />
                        ) : (
                          <BsChevronDown
                            className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
                            onClick={() => toggleRowExpansion(bot._id)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              }))}

            </div>

          </div>
        </div>
      </div>

      {bots && bots.length === 0 ? <></> :
        <Pagination
          pageCount={pageCount}
          onPageChange={onPageChange}
        />
      }

    </>
  );
};

export default TableBotsAll;