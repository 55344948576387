import React, { useState } from 'react';
import { Toaster, toast } from 'sonner';
import { BsPlusCircleFill, BsXCircleFill } from "react-icons/bs";
import { validateEmail, validatePassword } from '../../utils/validations';
import InputField from '../../components/Forms/Inputs/InputField';
import { USER, UserRoles } from '../../types/user';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import InputEmail from '../../components/Forms/Inputs/InputEmail';
import InputPassword from '../../components/Forms/Inputs/InputPassword';
import SimpleSelect from '../../components/Forms/Select/SimpleSelect';
import TextareaField from '../../components/Forms/Inputs/TextareaField';
import { useUsers } from '../../hooks/useUsers';

enum UserActions {
  Add = "add",
  Show = "show",
  Edit = "edit",
}

const optionsPermission = [
  { value: UserRoles.Admin, text: UserRoles.Admin },
  { value: UserRoles.Moderator, text: UserRoles.Moderator },
  { value: UserRoles.User, text: UserRoles.User },
];

interface UserProps {
  toggleModal: () => void;
  user?: USER;
  onSave?: (result: boolean) => void;
  action: string;
  updateUsers?: () => void;
}

const User: React.FC<UserProps> = ({ toggleModal, user, action, onSave, updateUsers }) => {
  const { createUser, updateUser } = useUsers();
  const [name, setName] = useState(user?.name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [password, setPassword] = useState(user?.password || '');
  const [permission, setPermission] = useState(user?.role || '');
  const [comment, setComment] = useState(user?.description || '');

  const handleSelectChange = (value: string) => {
    setPermission(value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (name === '' || email === '' || permission === '' || (action === UserActions.Add && password === '')) {
      toast.error('Please fill fields form');
      return;
    }

    const userData: Partial<USER> = {
      name,
      email,
      role: permission,
      description: comment,
      password,
    };

    let result = false;
    if (action === UserActions.Add) {
      result = await createUser(userData);
    } else if (action === UserActions.Edit && user?.id) {
      result = await updateUser({ ...userData, id: user.id });
    }

    if (result) {
      updateUsers?.();
      toggleModal();
      onSave?.(true);
    } else {
      onSave?.(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col h-full">
        <div className="flex-grow mb-2">
          <InputField
            label="Name"
            value={name}
            onChange={handleNameChange}
            placeholder="Enter name user"
            className="mb-4"
          />

          <SimpleSelect
            label='Permission'
            options={optionsPermission}
            value={permission}
            onChange={handleSelectChange}
            className="mb-4"
          />

          <InputEmail 
            value={email} 
            className='mb-4'
            onChange={handleEmailChange} />

          {action === UserActions.Add && 
            <InputPassword 
              className='mb-4'
              label='Password'
              value={password} 
              onChange={handlePasswordChange} 
              validate={validatePassword} />}

          <TextareaField value={comment} onChange={handleCommentChange} />
        </div>

        <div className='flex items-center justify-between gap-4 mt-auto'>
          <BtnPrimary 
            className='w-full' 
            type='submit'
            disabled={name === '' || email === '' || permission === '' || (action === UserActions.Add && password === '')}>
            <BsPlusCircleFill />  {action === UserActions.Add ? 'Add user' : 'Edit user'}
          </BtnPrimary>
          <BtnPrimary
            className='w-full'
            style="outline"
            onClick={() => {
              toggleModal();
            }}>
            <BsXCircleFill /> Close
          </BtnPrimary>
        </div>
      </form>
    </>
  );
};

export default User;