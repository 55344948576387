export const symbols = [
  {
    value: "BTC-USDT",
    text: "BTC-USDT",
  },
  {
    value: "ETH-USDT",
    text: "ETH-USDT",
  },
  {
    value: "1000PEPE-USDT",
    text: "1000PEPE-USDT",
  },
  {
    value: "RATS-USDT",
    text: "RATS-USDT",
  },
  {
    value: "ACE-USDT",
    text: "ACE-USDT",
  },
  {
    value: "ARB-USDT",
    text: "ARB-USDT",
  },
  {
    value: "BCH-USDT",
    text: "BCH-USDT",
  },
  {
    value: "CHZ-USDT",
    text: "CHZ-USDT",
  },
  {
    value: "DYDX-USDT",
    text: "DYDX-USDT",
  },
  {
    value: "LTC-USDT",
    text: "LTC-USDT",
  },
  {
    value: "NEAR-USDT",
    text: "NEAR-USDT",
  },
  {
    value: "SOL-USDT",
    text: "SOL-USDT",
  },
  {
    value: "TRX-USDT",
    text: "TRX-USDT",
  },
  {
    value: "DOGE-USDT",
    text: "DOGE-USDT",
  },
  {
    value: "XRP-USDT",
    text: "XRP-USDT",
  },
  {
    value: "AVAX-USDT",
    text: "AVAX-USDT",
  },
  {
    value: "BNB-USDT",
    text: "BNB-USDT",
  },
  {
    value: "ADA-USDT",
    text: "ADA-USDT",
  },
  {
    value: "ETC-USDT",
    text: "ETC-USDT",
  },
  {
    value: "CTC-USDT",
    text: "CTC-USDT",
  },
];

export const sides = [
  {
    value: "LONG",
    text: "LONG",
  },
  {
    value: "SHORT",
    text: "SHORT",
  },
]

export const ways = [
  {
    value: "ONLY UP",
    text: "ONLY UP",
  },
  {
    value: "ONLY DOWN",
    text: "ONLY DOWN",
  },
]