import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { Toaster } from 'sonner';
import { usePnlStatisticsContext, PnlStatisticsProvider } from '../../context/PnlStatisticsContext';
import { MdAccountBalanceWallet } from "react-icons/md";
import { formatCurrency } from '../../utils/formatCurrency';
import TablePnlStatistics from './PnlTable'
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import PnlFilterNew from './PnlFilterNew';
import { BsFunnelFill } from "react-icons/bs";
import { FaDownload } from "react-icons/fa";
import ModalSideRight from '../../components/Modals/ModalSideRight'

import api from '../../utils/api';
import { ApiResponse } from '../../types/api'
import { PnlStatisticsItem } from '../../types/pnlStatistic';

const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'PnL statistics', link: '/pnl-statistics' }
];

const PnlStatistics: React.FC = () => {
  return (
    <PnlStatisticsProvider>
      <PnlStatisticsContent />
    </PnlStatisticsProvider>
  );
};

const PnlStatisticsContent: React.FC = () => {
  const { data, startDate, endDate, selectedSymbols, selectedBots, selectedApiKeys } = usePnlStatisticsContext();
  const dataArray = data || []
  const [isModalFilter, setIsModalFilter] = useState(false);

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const downloadFile = async () => {
    let acc = selectedApiKeys
    if (selectedApiKeys.length == 0 && selectedBots.length > 0) {
      acc = selectedBots
    }
    try {
      fetch(`/api/api_keys/positions/period-report/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify({
          from: startDate,
          to: endDate,
          accounts: acc,
          symbols: selectedSymbols
        })
      })
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.download = 'pnl-report.xlsx';
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link)
        })
    } catch (error) {
      console.log("Errr data", error)
    }
  };

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <Toaster position="bottom-center" richColors />

      <div className='flex gap-4 mb-4'>
        <BtnPrimary
          onClick={toggleModalFilter}>
          <BsFunnelFill /> Filter
        </BtnPrimary>
        <BtnPrimary onClick={downloadFile}>
          <FaDownload /> Download data
        </BtnPrimary>
      </div>

      <ModalSideRight
        isOpen={isModalFilter}
        toggleModal={toggleModalFilter}
        title="FILTER"
        content={
          <PnlFilterNew toggleModal={toggleModalFilter} />
        }
      />

      {/* <PnlFilter /> */}
      <TablePnlStatistics data={dataArray} />
    </>
  );
};

export default PnlStatistics;