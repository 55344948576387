import React from 'react';
import { ApiKeysProvider } from '../../context/ApiKeysContext';
import ApiKeysContent from './ApiKeysContent';

const ApiKeys: React.FC = () => {
  return (
    <ApiKeysProvider>
      <ApiKeysContent />
    </ApiKeysProvider>
  );
};

export default ApiKeys;