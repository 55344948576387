import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api from '../utils/api';
import { toast } from 'sonner';
import { ApiResponse } from '../types/api';
import { PnlStatisticsItems, PnlStatisticsItem } from '../types/pnlStatistic';
import { KEY, GetApiKeysAll } from '../types/key'

interface PnlStatisticsContextProps {
  startDate: number;
  endDate: number;
  selectedSymbols: string[],
  selectedBots: string[],
  selectedApiKeys: string[],
  data: PnlStatisticsItem[] | null;
  fetchData: (from: number, to: number, accounts: string[], bots: string[], symbols: string[]) => Promise<void>;
  resetFilter: () => void;
  setSelectedSymbols: any;
  setSelectedBots: any;
  setSelectedApiKeys: any;
  setStartDate: any;
  setEndDate: any;
}

const PnlStatisticsContext = createContext<PnlStatisticsContextProps | undefined>(undefined);

export const usePnlStatisticsContext = () => {
  const context = useContext(PnlStatisticsContext);
  if (!context) {
    throw new Error('usePnlStatisticsContext must be used within a PnlStatisticsProvider');
  }
  return context;
};

interface PnlStatisticsProviderProps {
  children: React.ReactNode;
}

export const PnlStatisticsProvider: React.FC<PnlStatisticsProviderProps> = ({ children }) => {
  const currentDateStart = new Date(new Date().setUTCHours(0, 0, 0, 0));
  const currentDateEnd = new Date(new Date().setUTCHours(23, 59, 59, 999));
  const sDate = currentDateStart.setUTCDate(currentDateStart.getUTCDate() - 7);
  const eDate = currentDateEnd.getTime();

  const [startDate, setStartDate] = useState<number>(sDate);
  const [endDate, setEndDate] = useState<number>(eDate);
  const [selectedSymbols, setSelectedSymbols] = useState<string[]>([]);
  const [selectedBots, setSelectedBots] = useState<string[]>([]);
  const [selectedApiKeys, setSelectedApiKeys] = useState<string[]>([]);
  const [data, setData] = useState<PnlStatisticsItem[] | null>([]);

  useEffect(() => {
    fetchData(startDate, endDate, [], [], []);
  }, []);

  const fetchData = useCallback(async (from: number, to: number, accounts: string[], bots: string[], symbols: string[]) => {
    try {
      let acc = accounts
      if (accounts.length == 0 && bots.length > 0) {
        acc = bots
      }
      const response = await api.post<ApiResponse<PnlStatisticsItems>>(`/api/api_keys/positions/period-report`, {
        from,
        to,
        accounts: acc,
        // bots,
        symbols,
      });
      if (response.data && response.data.result) {
        setData(response.data.result.items);
        // setTotalCount(response.data.result.totalCount);
      } else {
        setData([]);
        // setTotalCount(0);
      }
    } catch (error) {
      toast.error('Failed to fetch PnL statistics');
    }
  }, []);

  const resetFilter = useCallback(() => {
    const currentDateStart = new Date(new Date().setUTCHours(0, 0, 0, 0));
    const currentDateEnd = new Date(new Date().setUTCHours(23, 59, 59, 999));
    const sDate = currentDateStart.setUTCDate(currentDateStart.getUTCDate() - 7);
    const eDate = currentDateEnd.getTime();

    setStartDate(sDate);
    setEndDate(eDate);

    setSelectedApiKeys([]);
    setSelectedBots([]);
    setSelectedSymbols([]);

    fetchData(sDate, eDate, [], [], []);
  }, [fetchData]);

  const contextValue: PnlStatisticsContextProps = {
    startDate,
    endDate,
    data,
    selectedSymbols,
    selectedBots,
    selectedApiKeys,
    fetchData,
    resetFilter,
    setSelectedSymbols,
    setSelectedBots,
    setSelectedApiKeys,
    setStartDate,
    setEndDate,
  };

  return (
    <PnlStatisticsContext.Provider value={contextValue}>
      {children}
    </PnlStatisticsContext.Provider>
  );
};