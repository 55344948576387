import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom'
import ModalCenter from '../../components/Modals/ModalCenter';
import ModalSideRight from "../../components/Modals/ModalSideRight";
import { KEY, OrderHistory, GetOrderHistoryAll, Position, AccountFuture, AccountSpot, InfoBingX } from "../../types/key";
import { Toaster, toast } from 'sonner';
import Key from "./Key";
import Pagination from '../../components/Pagination/Pagination';
import ContentConfirm from "../../components/Modals/ContentConfirm";
import { PiTrademarkFill } from "react-icons/pi";
import { BsClockHistory } from "react-icons/bs";
import NotFound from "../../common/NotFound";
import { formatCurrency } from "../../utils/formatCurrency";
import { RiRadioButtonLine } from "react-icons/ri";
import HistoryOrdersModal from '../HistoryOrders/HistoryOrdersModal'
import { FaMoneyBillTransfer } from "react-icons/fa6";
import ContentModalSend from "./ContentModalSend";
import { useApiKeysContext } from '../../context/ApiKeysContext';
import BotTooltip from '../../components/CustomTooltip/CustomTooltip'


interface TasksProps {
  keys: KEY[];
  totalCount: number;
  fetchKeys: (page: number, keysPerPage: number) => void;
  onPageChange: (selectedItem: { selected: number }) => void;
  currentPage: number;
  setKeys: React.Dispatch<React.SetStateAction<KEY[]>>;
}

const TableApiKeys: React.FC<TasksProps> = ({ keys, totalCount, fetchKeys, onPageChange, currentPage, setKeys, }) => {
  const {
    isModalTransfer,
    toggleModalTransfer,
    isModalDeleteOpen,
    isModalEditOpen,
    handleTransferOpen,
    selectedKey,
    isModalHistoryOrders,
    toggleModalHistoryOrders,
    handleHistoryOrdersKey,
    toggleModalDelete,
    toggleModalEdit,
    handleDeleteKey,
    handleDeleteConfirm,
    handleSaveUpdate,
    handleEditKey,
    orderHistory,
    orderHistoryPage,
    orderHistoryTotalCount,
    handleOrderHistoryPageChange,
    handleTransfer,
    keysAll
  } = useApiKeysContext();

  const keysPerPage = 10;
  const pageCount = Math.ceil((totalCount || 0) / keysPerPage);

  const offset = currentPage * keysPerPage;
  //const currentKeys = keys.slice(offset, offset + keysPerPage);

  const memoizedKeys = useMemo(() => keys, [keys])

  useEffect(() => {
    // Обновляем таблицу при изменении ключей
    console.log("keys:", keys);
  }, [memoizedKeys]);

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto">
          <div className="min-w-[1170px]">

            <div className="grid grid-cols-9 gap-4 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
              {/* <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">#</h5>
              </div> */}
              <div className="col-span-2 flex justify-center items-center">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">NAME</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">TYPE</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">BALANCE</h5>
              </div>
              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">POS/ORDERS</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">BOT NAME</h5>
              </div>
              <div className="col-span-2 flex justify-center items-center">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">ACTIONS</h5>
              </div>
            </div>

            <div className="bg-white dark:bg-boxdark">
              {keys == null || keys.length === 0 ? (
                <NotFound />
              ) : (
                memoizedKeys.map((memoizedKey, index) => (
                  <Row
                    keysAll={keysAll}
                    keys={keys}
                    handleTransfer={handleTransfer}
                    isModalTransfer={isModalTransfer}
                    toggleModalTransfer={toggleModalTransfer}
                    handleTransferOpen={handleTransferOpen}
                    isModalHistoryOrders={isModalHistoryOrders}
                    toggleModalHistoryOrders={toggleModalHistoryOrders}
                    handleHistoryOrdersKey={handleHistoryOrdersKey}
                    key={memoizedKey.id}
                    keyItem={memoizedKey}
                    index={index}
                    currentPage={currentPage}
                    keysPerPage={keysPerPage}
                    handleEditKey={handleEditKey}
                    handleDeleteKey={handleDeleteKey}
                    isModalEditOpen={isModalEditOpen}
                    toggleModalEdit={toggleModalEdit}
                    isModalDeleteOpen={isModalDeleteOpen}
                    toggleModalDelete={toggleModalDelete}
                    selectedKey={selectedKey}
                    handleDeleteConfirm={handleDeleteConfirm}
                    handleSaveUpdate={handleSaveUpdate}
                    updateKeys={() => fetchKeys(currentPage, 100)}
                    orderHistory={orderHistory}
                    orderHistoryPage={orderHistoryPage}
                    orderHistoryTotalCount={orderHistoryTotalCount}
                    handleOrderHistoryPageChange={handleOrderHistoryPageChange}
                  />
                ))
              )}
            </div>

          </div>
        </div>
      </div>

      {memoizedKeys && memoizedKeys.length > 0 &&
        <Pagination
          pageCount={pageCount}
          onPageChange={onPageChange}
        />
      }
    </>
  );
};

const Row = ({
  keysAll,
  keys,
  handleTransfer,
  toggleModalTransfer,
  isModalTransfer,
  keyItem,
  index,
  currentPage,
  isModalHistoryOrders,
  toggleModalHistoryOrders,
  handleTransferOpen,
  handleHistoryOrdersKey,
  keysPerPage,
  handleEditKey,
  handleDeleteKey,
  isModalEditOpen,
  toggleModalEdit,
  isModalDeleteOpen,
  toggleModalDelete,
  selectedKey,
  handleDeleteConfirm,
  handleSaveUpdate,
  updateKeys,
  orderHistory,
  orderHistoryPage,
  orderHistoryTotalCount,
  handleOrderHistoryPageChange,
}: {
  keysAll: KEY[],
  keys: KEY[],
  keyItem: KEY;
  index: number;
  currentPage: number;
  keysPerPage: number;
  handleEditKey: (key: KEY) => void;
  handleDeleteKey: (key: KEY) => void;
  isModalTransfer: boolean;
  isModalEditOpen: boolean;
  isModalHistoryOrders: boolean;
  toggleModalTransfer: () => void;
  toggleModalHistoryOrders: () => void;
  handleTransferOpen: (key: KEY) => void;
  handleHistoryOrdersKey: (key: KEY) => void;
  toggleModalEdit: () => void;
  isModalDeleteOpen: boolean;
  toggleModalDelete: () => void;
  selectedKey: KEY | null;
  handleTransfer: (result: boolean) => void;
  handleDeleteConfirm: (result: boolean) => void;
  handleSaveUpdate: (result: boolean) => void;
  updateKeys: () => void;
  orderHistory: OrderHistory[];
  orderHistoryPage: number;
  orderHistoryTotalCount: number;
  handleOrderHistoryPageChange: (selectedItem: { selected: number }) => void;
}) => {
  const navigate = useNavigate();

  const handleNavigateToManualTrading = () => {
    if (!keyItem?.bot?.settings?.symbol || keyItem?.bot?.settings?.symbol == "") {
      let sym = "BTC-USDT"
      if (keyItem.exchange_name.toUpperCase() == "BINGX") {
        sym = "BTC-USDT"
      } else if (keyItem.exchange_name.toUpperCase() == "BYBIT") {
        sym = "BTCUSDT"
      } else if (keyItem.exchange_name.toUpperCase() == "GATE") {
        sym = "BTC_USDT"
      } else if (keyItem.exchange_name.toUpperCase() == "MEXC") {
        sym = "BTCUSDT"
      } else if (keyItem.exchange_name.toUpperCase() == "BITGET") {
        sym = "BTCUSDT"
      } else if (keyItem.exchange_name.toUpperCase() == "BINANCE") {
        sym = "BTCUSDT"
      }
      navigate(`/manual-trading/${keyItem.id}/${sym}`);
    } else {
      navigate(`/manual-trading/${keyItem.id}/${keyItem?.bot?.settings?.symbol}`);
    }
  };

  // Функция для расчета суммы позиций и количества ордеров для выбранного символа
  const calculatePositionsAndOrders = (keyItem: KEY) => {

    if (!keyItem.bot || !keyItem.bot.symbol) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    }

    let positions = []
    let openOrder = []
    if (keyItem.exchange_name.toUpperCase() == "BINGX" && !keyItem.infoBingX) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    } else if (keyItem.exchange_name.toUpperCase() == "BYBIT" && !keyItem.infoBybit) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    } else if (keyItem.exchange_name.toUpperCase() == "GATE" && !keyItem.infoGate) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    } else if (keyItem.exchange_name.toUpperCase() == "MEXC" && !keyItem.infoMexc) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    } else if (keyItem.exchange_name.toUpperCase() == "BITGET" && !keyItem.infoBitget) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    } else if (keyItem.exchange_name.toUpperCase() == "BINANCE" && !keyItem.infoBinance) {
      return { totalPositionValue: 0, openOrdersCount: 0 };
    }

    if (keyItem.exchange_name.toUpperCase() == "BYBIT") {
      positions = keyItem.infoBybit.positions || [];
      openOrder = keyItem.infoBybit.openOrder || [];
    } else if (keyItem.exchange_name.toUpperCase() == "GATE") {
      positions = keyItem.infoGate.positions || [];
      openOrder = keyItem.infoGate.openOrder || [];
    } else if (keyItem.exchange_name.toUpperCase() == "MEXC") {
      positions = keyItem.infoMexc.positions || [];
      openOrder = keyItem.infoMexc.openOrder || [];
    } else if (keyItem.exchange_name.toUpperCase() == "BITGET") {
      positions = keyItem.infoBitget.positions || [];
      openOrder = keyItem.infoBitget.openOrder || [];
    } else if (keyItem.exchange_name.toUpperCase() == "BINANCE") {
      positions = keyItem.infoBinance.positions || [];
      openOrder = keyItem.infoBinance.openOrder || [];
    } else {
      positions = keyItem.infoBingX.positions || [];
      openOrder = keyItem.infoBingX.openOrder || [];
    }



    const filteredPositions = positions.filter(position => position.symbol === keyItem.bot.symbol);
    const filteredOpenOrders = openOrder.filter(order => order.symbol === keyItem.bot.symbol);

    const totalPositionValue = filteredPositions.reduce((sum, position) => sum + parseFloat(position.positionValue || '0'), 0);
    const openOrdersCount = filteredOpenOrders.length;

    return { totalPositionValue, openOrdersCount };
  };

  const { totalPositionValue, openOrdersCount } = calculatePositionsAndOrders(keyItem);
  const keyName = {
    name: keyItem.name,
  };
  const botName = {
    name: keyItem?.bot?.name,
  };

  // let accountFuture: AccountFuture
  // let accountSpot: AccountSpot
  let accountInfo: InfoBingX
  if (keyItem.exchange_name.toUpperCase() == "BYBIT") {
    accountInfo = keyItem.infoBybit;
  } else if (keyItem.exchange_name.toUpperCase() == "GATE") {
    accountInfo = keyItem.infoGate;
  } else if (keyItem.exchange_name.toUpperCase() == "MEXC") {
    accountInfo = keyItem.infoMexc;
  } else if (keyItem.exchange_name.toUpperCase() == "BITGET") {
    accountInfo = keyItem.infoBitget;
  } else if (keyItem.exchange_name.toUpperCase() == "BINANCE") {
    accountInfo = keyItem.infoBinance;
  } else {
    accountInfo = keyItem.infoBingX;
  }
  return (
    <div className="grid grid-cols-9 gap-4 border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11">
      {/* <div className="col-span-1">
        <div className="flex gap-3 items-left">
          <RiRadioButtonLine
            className={`${keyItem.invalid ? 'text-red-500' : 'text-green-500'}`}
          />
          <p className="text-[#637381] dark:text-bodydark">{currentPage * keysPerPage + index + 1}</p>
        </div>
      </div> */}
      <div className="col-span-2">
        <div className="flex gap-3 items-left">
          <p className="text-[#637381] dark:text-bodydark">{currentPage * keysPerPage + index + 1}</p>
          <RiRadioButtonLine
            className={`${keyItem.invalid ? 'text-red-500' : 'text-green-500'}`}
          />
          <BotTooltip bot={keyName} />
        </div>

      </div>
      <div className="col-span-1">
        <p className="text-[#637381] dark:text-bodydark">{`${keyItem?.exchange_name}`}</p>
      </div>
      <div className="col-span-1">
        {keyItem.market.toUpperCase() == "FUTURES" ?
          (<p className="text-[#637381] dark:text-bodydark">{accountInfo?.accountFuture?.balance ? formatCurrency(accountInfo?.accountFuture?.balance) : "------"}</p>)
          :
          (
            (accountInfo?.accountSpot || []).map((item, index) => (
              <p className="text-[#637381] dark:text-bodydark">{item.asset}: {item.free}/{item.locked}</p>
            ))
          )
        }

      </div>
      <div className="col-span-1">
        <p className="text-[#637381] dark:text-bodydark">{`${formatCurrency(totalPositionValue)} / ${openOrdersCount}`}</p>
      </div>
      <div className="col-span-2">
        {keyItem.bot?.name ? <BotTooltip bot={keyItem.bot} /> : <p className="text-[#637381] dark:text-bodydark">------</p>}
      </div>
      <div className="col-span-2 flex justify-end gap-4">

        <FaMoneyBillTransfer
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => {
            handleTransferOpen(keyItem)
          }}
        />

        <ModalCenter
          isOpen={isModalTransfer}
          toggleModal={toggleModalTransfer}
          content={
            selectedKey && (
              <ContentModalSend
                keyFrom={selectedKey}
                onHandle={(result) => {
                  handleTransfer(result);
                  // fet(currentPage);
                }}
                toggleModal={toggleModalTransfer}
                keys={keysAll}
              />
            )
          }
        />

        <BsClockHistory
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => {
            handleHistoryOrdersKey(keyItem)
          }}
        />

        <ModalSideRight
          isOpen={isModalHistoryOrders}
          toggleModal={toggleModalHistoryOrders}
          title="History Orders"
          content={
            selectedKey && (
              <HistoryOrdersModal
                isOpen={isModalHistoryOrders}
                toggleModal={toggleModalHistoryOrders}
                orderHistory={orderHistory}
                orderHistoryPage={orderHistoryPage}
                orderHistoryTotalCount={orderHistoryTotalCount}
                handleOrderHistoryPageChange={handleOrderHistoryPageChange}
              />
            )
          }
        />

        <PiTrademarkFill
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={handleNavigateToManualTrading}
        />

        <BsFillPencilFill
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => handleEditKey(keyItem)}
        />

        <ModalSideRight
          isOpen={isModalEditOpen}
          toggleModal={toggleModalEdit}
          title="EDIT KEY"
          content={
            selectedKey && (
              <Key
                toggleModal={toggleModalEdit}
                action="edit"
                keyItem={selectedKey}
                onSave={handleSaveUpdate}
                updateKeys={updateKeys} />
            )
          } />

        <BsFillTrashFill
          className="cursor-pointer duration-300 ease-in-out hover:text-primary text-xl"
          onClick={() => handleDeleteKey(keyItem)}
        />

        <ModalCenter
          isOpen={isModalDeleteOpen}
          toggleModal={toggleModalDelete}
          content={
            selectedKey && (
              <ContentConfirm
                toggleModal={toggleModalDelete}
                text="Are you sure you want to delete this user?"
                onHandle={handleDeleteConfirm}
                updateItems={updateKeys}
              />
            )
          }
        />
      </div>
    </div>
  );
};

export default TableApiKeys;