import React from 'react';
import { DealsStatProvider } from '../../context/DealsStatContext';
import DealsStatisticsContent from './DealsStatisticsContent';

const ApiKeys: React.FC = () => {
  return (
    <DealsStatProvider>
      <DealsStatisticsContent />
    </DealsStatProvider>
  );
};

export default ApiKeys;