import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api from '../utils/api';
import { ApiResponse } from '../types/api';
import { GetAllPositions, PositionItem } from '../types/positions';
import { toast } from 'sonner';

interface DealsStatContextProps {
  startDate: number;
  endDate: number;
  statistics: PositionItem[];
  setStatistics: React.Dispatch<React.SetStateAction<PositionItem[]>>;
  totalCount: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  error: string | null;
  pageSize: number;
  selectedBots: string[],
  selectedApiKeys: string[],
  selectedSymbols: string[];
  setSelectedSymbols: any;
  setSelectedBots: any;
  setSelectedApiKeys: any;
  setStartDate: any;
  setEndDate: any;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  getTransactionStatistics: (page: number, size: number, from: number, to: number, accounts: string[], bots: string[], symbols: string[]) => void;
  handlePageChange: (selectedItem: { selected: number }) => void;
  resetFilter: () => void;
}

const DealsStatContext = createContext<DealsStatContextProps | undefined>(undefined);

export const useDealsStatContext = () => {
  const context = useContext(DealsStatContext);
  if (!context) {
    throw new Error('useDealsStatContext must be used within a DealsStatProvider');
  }
  return context;
};

interface DealsStatProviderProps {
  children: React.ReactNode;
}

export const DealsStatProvider: React.FC<DealsStatProviderProps> = ({ children }) => {
  const currentDateStart = new Date(new Date().setUTCHours(0, 0, 0, 0));
  const currentDateEnd = new Date(new Date().setUTCHours(23, 59, 59, 999));
  const sDate = currentDateStart.setUTCDate(currentDateStart.getUTCDate() - 7);
  const eDate = currentDateEnd.getTime();

  const [startDate, setStartDate] = useState<number>(sDate);
  const [endDate, setEndDate] = useState<number>(eDate);

  const [selectedSymbols, setSelectedSymbols] = useState<string[]>([]);
  const [selectedBots, setSelectedBots] = useState<string[]>([]);
  const [selectedApiKeys, setSelectedApiKeys] = useState<string[]>([]);

  const [statistics, setStatistics] = useState<PositionItem[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);


  const [pageSize, setPageSize] = useState(() => {
    const savedPageSize = localStorage.getItem('dealsPageSize');
    return savedPageSize ? parseInt(savedPageSize, 10) : 25;
  });



  useEffect(() => {
    getTransactionStatistics(currentPage, pageSize, startDate, endDate, selectedApiKeys, selectedBots, selectedSymbols);
  }, []);

  useEffect(() => {
    localStorage.setItem('dealsPageSize', pageSize.toString());
  }, [pageSize]);

  const getTransactionStatistics = useCallback(async (page: number, size: number, from: number, to: number, accounts: string[], bots: string[], symbols: string[]) => {
    try {
      let acc = accounts
      if (accounts.length == 0 && bots.length > 0) {
        acc = bots
      }
      const response = await api.post<ApiResponse<GetAllPositions>>(`/api/api_keys/positions/history`, {
        from,
        to,
        accounts: acc,
        // bots,
        symbols,
        limit: size,
        page: page + 1,
      });
      if (response.data && response.data.result) {
        setStatistics(response.data.result.items);
        setTotalCount(response.data.result.totalCount);
      } else {
        setStatistics([]);
        setTotalCount(0);
      }
    } catch (error) {
      toast.error('An error occurred while fetching statistics');
      console.error('Error fetching statistics:', error);
    } finally {
      setIsLoading(false);
    }
  }, [api, setStatistics, setTotalCount]);

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    getTransactionStatistics(selectedItem.selected, pageSize, startDate, endDate, selectedApiKeys, selectedBots, selectedSymbols);
  };

  const resetFilter = useCallback(() => {
    const currentDateStart = new Date(new Date().setUTCHours(0, 0, 0, 0));
    const currentDateEnd = new Date(new Date().setUTCHours(23, 59, 59, 999));
    const sDate = currentDateStart.setUTCDate(currentDateStart.getUTCDate() - 7);
    const eDate = currentDateEnd.getTime();

    setStartDate(sDate);
    setEndDate(eDate);

    setSelectedApiKeys([]);
    setSelectedBots([]);
    setSelectedSymbols([]);
    setCurrentPage(0)
    getTransactionStatistics(currentPage, pageSize, sDate, eDate, [], [], []);
  }, [getTransactionStatistics, currentPage, pageSize]);

  const contextValue: DealsStatContextProps = {
    startDate,
    endDate,
    statistics,
    setStatistics,
    totalCount,
    currentPage,
    setCurrentPage,
    isLoading,
    error,
    pageSize,
    setPageSize,
    getTransactionStatistics,
    handlePageChange,
    resetFilter,
    selectedApiKeys,
    selectedBots,
    selectedSymbols,
    setSelectedSymbols,
    setSelectedBots,
    setSelectedApiKeys,
    setStartDate,
    setEndDate,
  };

  return (
    <DealsStatContext.Provider value={contextValue}>
      {children}
    </DealsStatContext.Provider>
  );
};