import React, { useState, useEffect } from 'react';
import { BsFillTrashFill, BsFillPencilFill, BsChevronDown, BsChevronUp, BsPlusCircleFill, BsClockHistory } from "react-icons/bs";
import { IoSettings } from "react-icons/io5";
import { GrDetach } from "react-icons/gr";
import { Toaster, toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import NotFound from '../../common/NotFound/index'
import { GetAllPositions, PositionItem } from '../../types/positions'
import { formatCurrency } from '../../utils/formatCurrency'
import { RiRadioButtonLine } from "react-icons/ri";
import Pagination from '../../components/Pagination/Pagination'
import { PnlStatisticsItem } from '../../types/pnlStatistic'

interface TranStatisticsProps {
  data: PnlStatisticsItem[] | []
}

const TablePnlStatistics: React.FC<TranStatisticsProps> = ({
  data
}) => {
  const [sortedPnlStatistics, setSortedPnlStatistics] = useState<PnlStatisticsItem[] | []>([]);

  useEffect(() => {
    // Сортируем данные по timestamp в порядке убывания
    const sortedData = [...data].sort((a, b) => b.timestamp - a.timestamp);
    setSortedPnlStatistics(sortedData);
  }, [data]);

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-x-auto">
          <div className="min-w-[700px]">

            <div className="grid grid-cols-12 gap-4 bg-[#F9FAFB] dark:bg-meta-4 px-5 py-4 lg:px-7.5 2xl:px-11">
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">DATE</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">DEALS</h5>
              </div>
              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">VOLUME</h5>
              </div>
              <div className="col-span-3">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">FEE</h5>
              </div>
              <div className="col-span-3">
                <h5 className="font-medium text-[#637381] dark:text-bodydark text-sm">PNL</h5>
              </div>
            </div>

            <div className="bg-white dark:bg-boxdark">

              {sortedPnlStatistics?.length === 0 ? (
                <NotFound />
              ) : (sortedPnlStatistics?.map((item, key) => {
                const isLastItem = key === sortedPnlStatistics?.length - 1;

                return (
                  <div className={`border-t border-[#EEEEEE] dark:border-strokedark ${isLastItem ? 'bg-[#F9FAFB] dark:bg-meta-4' : ''}`} key={key}>
                    <div className="grid grid-cols-12 gap-4 px-5 py-4 lg:px-7.5 2xl:px-11">

                      <div className="col-span-2 flex items-start gap-2">
                        <p className={`text-[#637381] dark:text-bodydark ${item.timestamp === 0 && 'uppercase'}`}>
                          { item.timestamp === 0 ? 'TOTAL' : new Date(item.timestamp).toLocaleDateString()}
                        </p>
                      </div>

                      <div className="col-span-2 items-start">
                        <p className={`text-[#637381] dark:text-bodydark`}>{item.positions}</p>
                      </div>

                      <div className="col-span-2 items-start">
                        <p className={`text-[#637381] dark:text-bodydark`}>{formatCurrency(item.volume)}</p>
                      </div>

                      <div className="col-span-3 flex flex-col items-start">
                        <p className={`text-[#637381] dark:text-bodydark`}>Fee: {formatCurrency(item.fee)}</p>
                        <p className={`text-[#637381] dark:text-bodydark`}>FundingFee: {formatCurrency(item.fundingFee)}</p>
                      </div>

                      <div className={`col-span-3 flex items-start ${item.pnl > 0 ? 'text-[#3CA745]' : 'text-[#FF5733]'}`}>
                        {formatCurrency(item.pnl)}
                      </div>
                    </div>
                  </div>
                );
              }))}

            </div>

          </div>
        </div>
      </div>

    </>
  );
};

export default TablePnlStatistics;