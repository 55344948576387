import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'sonner';
import { BsPlusCircleFill, BsXCircleFill } from "react-icons/bs";
import SimpleSelect from '../../../components/Forms/Select/SimpleSelect';
import BtnPrimary from '../../../components/Buttons/BtnPrimary';
import api from '../../../utils/api';
import { Db_Bots } from '../../../types/botsAll';
import { ApiResponse } from '../../../types/api';
import { KEY, GetApiKeysAll } from '../../../types/key';

interface AttachBotProps {
  bot: Db_Bots;
  onAttach: (result: boolean) => void;
  onClose: () => void;
}

const AttachDetachBot: React.FC<AttachBotProps> = ({ bot, onAttach, onClose }) => {
  const [selectedApiKey, setSelectedApiKey] = useState<string | null>(null);
  const [isKeysFetched, setIsKeysFetched] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [keys, setKeys] = useState<KEY[]>([]);

  const fetchKeys = async (page: number) => {
    try {
      const response = await api.post<ApiResponse<GetApiKeysAll>>(`/api/api_keys/search`, {
        "pagination": {
          "page": page + 1,
          "limit": 100
        }
        }
      );
      if (response.data.code === 200 && response.data.result) {
        setKeys(response.data.result.items);
      } else {
        setError('Failed to get all api keys');
      }
    } catch (error) {
      setError('An error occurred while fetching bots');
    }
  };

  useEffect(() => {
    // Выполняем запрос на получение ключей при открытии модального окна, если они еще не были получены
    if (!isKeysFetched) {
      fetchKeys(0);
      setIsKeysFetched(true);
    }

    if (bot?.api?.id) {
      setSelectedApiKey(bot.api.id);
    } else {
      setSelectedApiKey(null);
    }
  }, [isKeysFetched, bot]);

  const handleApiKeyChange = (value: string) => {
    setSelectedApiKey(value);
  };

  const handleAttach = async () => {
    if (!selectedApiKey) {
      setError('Please select an API key');
      return;
    }

    try {
      const response = await api.post(`/bot-api/bots/attach-api-key/${bot._id}`, {
        api_key_id: selectedApiKey
      });

      if (response.data.code === 200 && response.data.result) {
        toast.success('API key attached successfully');
        onAttach(true);
        onClose();
      } else {
        setError('Failed to attach API key');
        onAttach(false);
      }
    } catch (error) {
      setError('An error occurred while attaching API key');
      onAttach(false);
    }
  };

  const transformKeysToOptions = (keys: KEY[]) => {
    return keys.map(key => ({
      value: key.id,
      text: key.name
    }));
  };

  return (
    <>
      {/* <Toaster position="bottom-center" richColors /> */}
      <div>
        <svg
          className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
        <h3 className=" text-lg font-normal text-gray-500 dark:text-gray-400">
          Choose API key for <span className='dark:text-white text-black'>{bot.name}</span> bot
        </h3>
        <SimpleSelect
          label='API Key'
          options={[{ value: '', text: 'Select option...' }, ...transformKeysToOptions(keys)]}
          value={selectedApiKey || ''}
          onChange={handleApiKeyChange}
          className="mb-4"
        />
        <div className='flex items-center justify-between gap-4 mt-auto'>
          <BtnPrimary
            className='w-full'
            onClick={handleAttach}
          >
            <BsPlusCircleFill /> Attach API Key
          </BtnPrimary>
          <BtnPrimary
            className='w-full'
            style="outline"
            onClick={onClose}
          >
            <BsXCircleFill /> Close
          </BtnPrimary>
        </div>
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>
    </>
  );
};

export default AttachDetachBot;