import axios from 'axios';
import { ApiResponse, AuthResult } from '../types/api';
import api from '../utils/api';

export const refreshTokens = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  console.log("call refresh function");
  try {
    const refreshResponse = await axios.post<ApiResponse<AuthResult>>('/api/auth/refresh', { refresh_token: refreshToken });

    if (refreshResponse.data.code === 200 && refreshResponse.data.result) {
      localStorage.setItem('accessToken', refreshResponse.data.result.accessToken);
      localStorage.setItem('refreshToken', refreshResponse.data.result.refreshToken);
      console.log('Токен доступа успешно обновлен');
      /** Проверка наличия переменной symbols в LocalStorage и ее запрос и сохранение */
      if(!localStorage.getItem('symbols'))  {
        const accessToken = localStorage.getItem('accessToken');
        const symbolsResponse = await axios.get<ApiResponse<AuthResult>>('/api/symbols', {
          headers: { Authorization: `Bearer ${accessToken}` },
        }); //запрос symbols с api

        // Искусственная задержка после получения ответа от сервера
        await new Promise(resolve => setTimeout(resolve, 1000));

        if (symbolsResponse.data.code === 200 && symbolsResponse.data.result) {
          localStorage.setItem('symbols', JSON.stringify(symbolsResponse.data.result));
          // console.log(localStorage.getItem('symbols'))
        }
        return true;

      }
      return true;
    }
  } catch (refreshError) {    
    console.log('Не удалось обновить токен доступа');
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    // localStorage.removeItem('symbols');
    window.location.href = '/';
    return false;
  }

  return false;
};