import React from 'react';
import NotFound from '../../../common/NotFound';

interface Option {
  value: string;
  text: string;
}

interface SimpleSelectProps {
  label?: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
  widthFull?: boolean;
  disabled?: boolean; 
}

const SimpleSelect: React.FC<SimpleSelectProps> = ({ label, options, value, onChange, className, widthFull = true, disabled = false }) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={`relative ${className}`}>
      {label && <label className="block font-medium text-[#637381] dark:text-white mb-2.5">{label}</label>}
      <select
        value={value}
        onChange={handleChange}
        disabled={disabled} 
        className={`${widthFull ? 'w-full' : 'w-auto' } rounded-lg border-[1.5px] border-stroke bg-transparent p-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
      >
        <option value="" disabled hidden>Select option...</option>

        {
          options == null || options.length === 0 ? <NotFound/> : 
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))
        }

        {/* {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))} */}
      </select>
    </div> 
  );
};

export default SimpleSelect;