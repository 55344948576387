import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TableApiKeys from './TableApiKeys';
import ModalSideRight from '../../components/Modals/ModalSideRight';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import { BsPlusCircleFill } from "react-icons/bs";
import Key from './Key';
import { Toaster, toast } from 'sonner';
import { HiOutlineRefresh } from "react-icons/hi";
import { useApiKeysContext } from '../../context/ApiKeysContext';
import Switcher from "../../components/Switchers/Switcher";


const breadcrumbItems = [
  { name: 'Dashboard', link: '/dashboard' },
  { name: 'Api keys', link: '/api-keys' }
];


let refreshInterval: NodeJS.Timeout | null = null;

const ApiKeysContent: React.FC = () => {
  const [isModalSideOpen, setIsModalSideOpen] = useState(false);
  const [isActiveRefresh, setIsActiveRefresh] = useState(false);
  const { keys, totalCount, fetchKeys, handlePageChange, currentPage, setKeys, keysPerPage } = useApiKeysContext();
  const location = useLocation();

  const toggleModalSide = () => {
    setIsModalSideOpen(!isModalSideOpen);
  };

  const handleSave = (result: boolean) => {
    if (result) {
      toast.success('Key added successfully');
      fetchKeys(currentPage, keysPerPage);
    } else {
      toast.error('Failed to add key');
    }
  };

  const handleRefresh = () => {
    fetchKeys(currentPage, keysPerPage); 
  };

  let activeRefresh = localStorage.getItem("autoRefresh") ? localStorage.getItem("autoRefresh") : "1"
  
  const handleSwitcherChange = async () => {
    if(!activeRefresh) {
      localStorage.setItem("autoRefresh", "1")
    } else {
      if(activeRefresh === "0") {
        // отправляет refresh-запрос
        activeRefresh = "1"
        console.log(`Current location pathname is "${location.pathname}"`);
        let timeToRefreshQwery = 1 * 30 * 1000; // 30 сек 
        switch (location.pathname) {
          case '/api-keys':
            refreshInterval = setInterval(handleRefresh, timeToRefreshQwery);
            break;
        }
      } else {
        // не отправляем запрос, только меняем значение переменной на 0
        activeRefresh = "0"

      }
      localStorage.setItem("autoRefresh", `${activeRefresh}`)
      setIsActiveRefresh(Boolean(Number(activeRefresh)))
    }
  };

  return (
    <>
      <Toaster position="bottom-center" richColors />
      <Breadcrumb items={breadcrumbItems} />
      <div className='flex gap-4'>
        <BtnPrimary onClick={toggleModalSide} className='mb-4'><BsPlusCircleFill /> Add key</BtnPrimary>
        {!Boolean(Number(isActiveRefresh))  &&
          <BtnPrimary onClick={handleRefresh} className='mb-4'><HiOutlineRefresh /> Refresh</BtnPrimary>
        }
        <Switcher
          isActive={Boolean(Number(isActiveRefresh))}
          onChange={() => {handleSwitcherChange() }}
          id="autorefresh"
        />
      </div>
      
      <ModalSideRight
        isOpen={isModalSideOpen}
        toggleModal={toggleModalSide}
        title="NEW KEY"
        content={ <Key toggleModal={toggleModalSide} action='add' onSave={handleSave} />}
      />
     
      <TableApiKeys 
        keys={keys} 
        totalCount={totalCount} 
        fetchKeys={fetchKeys} 
        onPageChange={handlePageChange} 
        currentPage={currentPage} 
        setKeys={setKeys} 
      />
    </>
  );
};

export default ApiKeysContent;