
export const types = [
  {
    value: "Market",
    text: "Market",
  },
  {
    value: "Limit",
    text: "Limit",
  },
  {
    value: "Take Profit",
    text: "Take Profit",
  },
  {
    value: "Stop Loss",
    text: "Stop Loss",
  },
];

export const typesForSpot = [
  {
    value: "Market",
    text: "Market",
  },
  {
    value: "Limit",
    text: "Limit",
  },
];