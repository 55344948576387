import React, { createContext, useContext, useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import api from '../utils/api';
import { ApiResponse } from '../types/api';
import { Db_Bots } from '../types/botsAll';
import { GetApiKeysAll, KEY, OrderHistory, GetOrderHistoryAll } from '../types/key';
import { toast } from 'sonner';

interface BotsContextProps {
  botsAll: Db_Bots[];
  isLoading: boolean;
  error: string | null;
  selectedBot: Db_Bots | null;
  totalCount: number;
  currentPage: number;
  isModalConfirmOpen: boolean;
  isModalCommentOpen: boolean;
  isModalDetach: boolean;
  confirmBotUuid: string | null;
  switcherStates: { [uuid: string]: boolean };
  isModalAttachKeyOpen: boolean;
  selectedApiKey: string | null;
  isModalHistoryOrders: boolean;
  isModalEditOpen: boolean;
  selectedKey: KEY | null;
  orderHistory: OrderHistory[];
  orderHistoryPage: number;
  orderHistoryTotalCount: number;
  setBotsAll: Dispatch<SetStateAction<Db_Bots[]>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string | null>>;
  setSelectedBot: Dispatch<SetStateAction<Db_Bots | null>>;
  setTotalCount: Dispatch<SetStateAction<number>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setIsModalConfirmOpen: Dispatch<SetStateAction<boolean>>;
  setIsModalCommentOpen: Dispatch<SetStateAction<boolean>>;
  setIsModalDetach: Dispatch<SetStateAction<boolean>>;
  setConfirmBotUuid: Dispatch<SetStateAction<string | null>>;
  setSwitcherStates: Dispatch<SetStateAction<{ [uuid: string]: boolean }>>;
  setIsModalAttachKeyOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedApiKey: Dispatch<SetStateAction<string | null>>;
  setIsModalHistoryOrders: Dispatch<SetStateAction<boolean>>;
  setIsModalEditOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedKey: Dispatch<SetStateAction<KEY | null>>;
  setOrderHistory: Dispatch<SetStateAction<OrderHistory[]>>;
  setOrderHistoryPage: Dispatch<SetStateAction<number>>;
  setOrderHistoryTotalCount: Dispatch<SetStateAction<number>>;
  toggleModalEdit: () => void;
  toggleModalConfirm: () => void;
  toggleModalDetach: () => void;
  toggleModalAttachKey: () => void;
  toggleModalHistoryOrders: () => void;
  toggleModalComment: () => void;
  handleCommentBot: (bot: Db_Bots) => void;
  handleAttachKey: (result: boolean) => void;
  handleOrderHistoryPageChange: (selectedItem: { selected: number }) => void;
  fetchOrderHistory: (subAccountId: string, page: number) => void;
  handleHistoryOrdersKey: (key: string) => void;
  handleActivateConfirm: (result: boolean) => void;
  getBotsAll: (page: number) => void;
  activateBot: (uuid: string) => void;
  deactivateBot: (uuid: string) => void;
  handleActiveChange: (uuid: string, isActive: boolean) => void;
  handleSaveUpdate: (result: boolean) => void;
  handleEditBot: (bot: Db_Bots) => void;
  transformKeysToOptions: (keys: KEY[]) => { value: string; label: string }[];
  handlePageChange: (selectedItem: { selected: number }) => void;
  handleDetach: (bot: Db_Bots) => void;
  detachKey: () => Promise<boolean>;
  handleApiKeyDetach: (result: boolean) => void;
}

const BotsContext = createContext<BotsContextProps | undefined>(undefined);

export const useBotsContext = () => {
  const context = useContext(BotsContext);
  if (!context) {
    throw new Error('useBotsContext must be used within a BotsProvider');
  }
  return context;
};

export const BotsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [botsAll, setBotsAll] = useState<Db_Bots[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedBot, setSelectedBot] = useState<Db_Bots | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isModalCommentOpen, setIsModalCommentOpen] = useState(false);
  const [isModalDetach, setIsModalDetach] = useState(false);
  const [confirmBotUuid, setConfirmBotUuid] = useState<string | null>(null);
  const [switcherStates, setSwitcherStates] = useState<{ [uuid: string]: boolean }>({});
  const [isModalAttachKeyOpen, setIsModalAttachKeyOpen] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState<string | null>(null);
  const [isModalHistoryOrders, setIsModalHistoryOrders] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState<KEY | null>(null);
  const [orderHistory, setOrderHistory] = useState<OrderHistory[]>([]);
  const [orderHistoryPage, setOrderHistoryPage] = useState(0);
  const [orderHistoryTotalCount, setOrderHistoryTotalCount] = useState(0);
  const orderHistoryPerPage = 10;

  const toggleModalEdit = () => setIsModalEditOpen(!isModalEditOpen);
  const toggleModalConfirm = () => setIsModalConfirmOpen(!isModalConfirmOpen);
  const toggleModalDetach = () => setIsModalDetach(!isModalDetach);
  const toggleModalAttachKey = () => setIsModalAttachKeyOpen(!isModalAttachKeyOpen);
  const toggleModalHistoryOrders = () => setIsModalHistoryOrders(!isModalHistoryOrders);
  const toggleModalComment = () => setIsModalCommentOpen(!isModalCommentOpen);

  const handleCommentBot = (bot: Db_Bots) => {
    setSelectedBot(bot);
    toggleModalComment();
  };

  useEffect(() => {
    getBotsAll(currentPage);
  }, [currentPage]);

  const handleAttachKey = async (result: boolean) => {
    if (result && selectedBot?._id) {
      await getBotsAll(currentPage);
    }
    toggleModalAttachKey();
  };

  const handleOrderHistoryPageChange = (selectedItem: { selected: number }) => {
    setOrderHistoryPage(selectedItem.selected);
    if (selectedKey?.subAccountId) {
      fetchOrderHistory(selectedKey.subAccountId, selectedItem.selected);
    }
  };

  const fetchOrderHistory = useCallback(async (subAccountId: string, page: number) => {
    try {
      const response = await api.get<ApiResponse<GetOrderHistoryAll>>(`/api/api_keys/orders/${subAccountId}?page=${page + 1}&pageSize=${orderHistoryPerPage}`);
      if (response.data && response.data.result) {
        setOrderHistory(response.data.result.items);
        setOrderHistoryTotalCount(response.data.result.totalCount);
      } else {
        setOrderHistory([]);
        setOrderHistoryTotalCount(0);
      }
    } catch (error) {
      console.error('Error fetching order history:', error);
    }
  }, [api, orderHistoryPerPage, setOrderHistory, setOrderHistoryTotalCount]);

  const handleHistoryOrdersKey = (key: string) => {
    toggleModalHistoryOrders();
    if (key) {
      fetchOrderHistory(key, orderHistoryPage);
    }
  };

  const handleActivateConfirm = async (result: boolean) => {
    if (result && confirmBotUuid) {
      handleActiveChange(confirmBotUuid, switcherStates[confirmBotUuid]);
    }
    toggleModalConfirm();
  };

  const getBotsAll = useCallback(async (page: number) => {
    try {
      const response = await api.post(`/bot-api/bots/`, {
        // "filters": {
        //     "active": true,
        //     "favorite": false,
        //     "exchange": ["bingx", "binance"],
        //     "search": "bot_",
        //     "apiAttached": true,
        //     "market": "futures"
        //   },
        //   "sort": {
        //     "field": "created_at",
        //     "order": "asc"
        //   },
          "pagination": {
            "page": 1,
            "limit": 25
          }
      });
      if (response.data && response.data.result) {
        setBotsAll(response.data.result.items);
        setTotalCount(response.data.result.totalCount);
        const initialSwitcherStates: { [uuid: string]: boolean } = {};
        response.data.result.items.forEach((bot: Db_Bots) => {
          initialSwitcherStates[bot.uuid] = bot.active;
        });
        setSwitcherStates(initialSwitcherStates);
      } else {
        setBotsAll([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error('Error fetching keys:', error);
    } finally {
      setIsLoading(false);
    }
  }, [api, setBotsAll, setTotalCount]);

  const activateBot = async (uuid: string) => {
    try {
      const response = await api.post(`/bot-api/bots/${uuid}/activate`);
      if (response.data.code === 200 && response.data.result) {
        const bot = botsAll.find(bot => bot.uuid === confirmBotUuid);
        if (bot) {
          setSelectedBot(bot);
        }
        toast.success(`Bot ${bot?.name} activated successfully`);
      } else {
        setError('Failed to activate bot');
      }
    } catch (error) {
      setError('An error occurred while activating bot');
    }
  };

  const deactivateBot = async (uuid: string) => {
    try {
      const response = await api.post(`/bot-api/bots/${uuid}/deactivate`);
      if (response.data.code === 200 && response.data.result) {
        const bot = botsAll.find(bot => bot.uuid === confirmBotUuid);
        if (bot) {
          setSelectedBot(bot);
        }
        toast.success(`Bot ${bot?.name} deactivated successfully`);
      } else {
        setError('Failed to deactivate bot');
      }
    } catch (error) {
      setError('An error occurred while deactivating bot');
    }
  };

  const handleActiveChange = (uuid: string, isActive: boolean) => {
    if (isActive) {
      deactivateBot(uuid);
    } else {
      activateBot(uuid);
    }
    setSwitcherStates(prevStates => ({
      ...prevStates,
      [uuid]: !isActive
    }));
  };

  const handleSaveUpdate = (result: boolean) => {
    if (result) {
      toast.success('Bot edited successfully');
    } else {
      toast.error('Failed to edit bot');
    }
  };

  const handleEditBot = (bot: Db_Bots) => {
    setSelectedBot(bot);
    toggleModalEdit();
  };

  const transformKeysToOptions = (keys: KEY[]) => {
    return keys.map(key => ({
      value: key.id,
      label: key.name
    }));
  };

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    getBotsAll(selectedItem.selected);
  };

  const handleDetach = (bot: Db_Bots) => {
    setSelectedBot(bot);
    if (bot?.active) {
      toast.error('First turn off the bot');
    } else {
      toggleModalDetach();
    }
  };

  const detachKey = async () => {
    try {
      const response = await api.post(`/bot-api/bots/detach-api-key/${selectedBot?._id}`, {
        api_key_id: selectedApiKey
      });
      if (response.data.code === 200 && response.data.result) {
        console.log("API key detached successfully");
        return true;
      } else {
        setError('Failed to detach API key');
        return false;
      }
    } catch (error) {
      setError(`${error}`);
      return false;
    }
  };

  const handleApiKeyDetach = async (result: boolean) => {
    if (result && selectedBot?._id) {
      try {
        const success = await detachKey();
        if (success) {
          toast.success('API key detached successfully');
          setSelectedApiKey(null);
          getBotsAll(currentPage);
        } else {
          toast.error('Failed to detach API key');
        }
      } catch (error) {
        setError('Failed to detach API key');
      }
    }
  };

  const contextValue = {
    botsAll,
    isLoading,
    error,
    selectedBot,
    totalCount,
    currentPage,
    isModalConfirmOpen,
    isModalCommentOpen,
    isModalDetach,
    confirmBotUuid,
    switcherStates,
    isModalAttachKeyOpen,
    selectedApiKey,
    isModalHistoryOrders,
    isModalEditOpen,
    selectedKey,
    orderHistory,
    orderHistoryPage,
    orderHistoryTotalCount,
    setBotsAll,
    setIsLoading,
    setError,
    setSelectedBot,
    setTotalCount,
    setCurrentPage,
    setIsModalConfirmOpen,
    setIsModalCommentOpen,
    setIsModalDetach,
    setConfirmBotUuid,
    setSwitcherStates,
    setIsModalAttachKeyOpen,
    setSelectedApiKey,
    setIsModalHistoryOrders,
    setIsModalEditOpen,
    setSelectedKey,
    setOrderHistory,
    setOrderHistoryPage,
    setOrderHistoryTotalCount,
    toggleModalEdit,
    toggleModalConfirm,
    toggleModalDetach,
    toggleModalAttachKey,
    toggleModalHistoryOrders,
    toggleModalComment,
    handleCommentBot,
    handleAttachKey,
    handleOrderHistoryPageChange,
    fetchOrderHistory,
    handleHistoryOrdersKey,
    handleActivateConfirm,
    getBotsAll,
    activateBot,
    deactivateBot,
    handleActiveChange,
    handleSaveUpdate,
    handleEditBot,
    transformKeysToOptions,
    handlePageChange,
    handleDetach,
    detachKey,
    handleApiKeyDetach,
  };

  return (
    <BotsContext.Provider value={contextValue}>
      {children}
    </BotsContext.Provider>
  );
};